import React, { useState, useEffect } from 'react';
import classes from './CashManager.module.scss';
import FourDots from '../../UI/Icons/FourDots/FourDots';
import SearchEngine from '../../UI/SearchEngine/SearchEngine';
import InStoreTransactionCard from './InStoreTransactionCard';
import TransactionDetailsCard from './TransactionDetailsCard';
import FilterModal from '../Modals/CashManager/FilterModal';
import Pagination from "react-js-pagination";
import { CounterQuery, CashManagerQuery } from "./CashManagerQuery"
import users_query from '../../../queries/LoggedUser';
import receive_client from "../../../Client";

var dateFormat = require('dateformat');

const CashManager = (props) => {

  const [orders, set_orders] = useState(null)
  const [active_page, setActivePage] = useState(1)
  const [pagination_counter, set_pagination_counter] = useState(0)
  const [offset, setOffset] = useState(0)
  const [user_id, setUserId] = useState(null)
  const [filter_attributes, set_filter_attributes] = useState({})
  const [showTrasactionDetailsCard, setShowTrasactionDetailsCard] = useState(false);
  const [transactionSelectedIndex, setTransactionSelectedIndex] = useState(null);
  const [selected_order, setSelectedOrder] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false)

  useEffect(() => {
    filter_orders(filter_attributes)
  }, [offset]);

  useEffect(() => {
    setCurrentUserId()
  }, []);

  const setCurrentUserId = async () => {
    const client = receive_client()
    const result = await client.query({ query: users_query })
    setUserId(result.data.currentUser.id)
  }

  const showDetailsCard = (type, index, id) => {
    if (index === transactionSelectedIndex) {
      setShowTrasactionDetailsCard(false);
      setTransactionSelectedIndex(null);
    }
    else {
      setShowTrasactionDetailsCard(true);
      setTransactionSelectedIndex(index);
      setSelectedOrder(orders.find(order => order.id === id))
    }
  }

  const handlePageChange = async pageNumber => {
    setActivePage(pageNumber)
    setOffset((pageNumber - 1) * 7)
    setShowTrasactionDetailsCard(false);
    setTransactionSelectedIndex(null);
    const scrollOptions = {
      left: 0,
      top: 0,
      behavior: 'smooth'
    }
    window.scrollTo(scrollOptions);
  };

  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  }

  const closeFilterModal = () => {
    setShowFilterModal(false);
  }

  const afterFilter = (variables) => {
    filter_orders(variables)
    set_filter_attributes(variables)
    setOffset(0)
    setActivePage(1)
  }

  const filter_orders = (variables = null) => {
    let new_variables = Object.assign({}, variables);
    new_variables.offset = offset
    CashManagerQuery(new_variables).then(result => {
      CounterQuery(variables).then(result_counter => {
        set_orders(result.data.orders)
        set_pagination_counter(result_counter.data.countCashManager)
      })
    })
  }



  const search_field_value = (value) => {
    let new_variables = Object.assign({}, filter_attributes);
    new_variables.accountName = value
    filter_orders(new_variables)
    set_filter_attributes({ ...filter_attributes, accountName: value })
    setOffset(0)
    setActivePage(1)
  }


  if (!orders) {
    return null;
  }

  const order_list = orders.map((order, index) => {
    let order_type = null

    if (order.amountPurchasedCents) {
      order_type = "purchase"
    }
    else {
      order_type = "sale"
    }

    return <InStoreTransactionCard
      key={index}
      index={index}
      order_id={order.id}
      type={order_type}
      amount={order_type === "purchase" ? order.amountPurchasedCents : order.amountSoldCents}
      paid_price={order.amountPaidCents}
      rate={order.rate}
      currency={order.amountPurchasedCurrency || order.amountSoldCurrency}
      paid_currency={order.amountPaidCurrency}
      order_number={order.number}
      customer={order.customer ? order.customer.firstName + " " + order.customer.lastName : null}
      datetime={dateFormat(order.datetime, "mmmm dd, yyyy")}
      showDetails={showDetailsCard}
      transactionSelectedIndex={transactionSelectedIndex}
    />
  })


  const obj = filter_attributes;
  let filtering_string = "";
  Object.keys(obj).forEach(function (key) {
    if (obj[key] != null) {
      let formated_key = key.replace(/([A-Z])/g, '_$1').trim().toLowerCase()
      filtering_string = filtering_string.concat(formated_key + "=" + obj[key] + "&")
    }
  });

  filtering_string = filtering_string + `orders_typology=service_point&user_id=${user_id}`;
  const url = `${process.env.REACT_APP_HOME_URL}/file/order_details?${filtering_string}`;

  return (
    <div className={classes.Page}>
      <div className={classes.PageColumn}>
        <div className={classes.Title}>
          <span>IN-STORE ORDERS</span>
          <div className="filterModalIcon" style={{ position: "relative" }} onClick={() => toggleFilterModal()}>
            <FourDots size={18} dotSize={7.37} />
            {showFilterModal ? <FilterModal getData={afterFilter} attributes={filter_attributes} closeModal={closeFilterModal} /> : null}
          </div>
          <a href={user_id ? url : ""}>
            <i className="fas fa-share-square" style={{
              color: "rgb(211, 156, 62)",
              fontSize: "18px",
              marginRight: "10px"
            }}></i>
          </a>
        </div>

        <SearchEngine placeholder='Search for an order' filter_search_filed={search_field_value} extraStyle={{ margin: "30px 0" }} />
        {order_list}
        <div className={classes.order_pagination}>
          <Pagination
            activePage={active_page}
            itemsCountPerPage={7}
            totalItemsCount={orders ? pagination_counter : 0}
            pageRangeDisplayed={9}
            prevPageText={null}
            nextPageText={null}
            activeLinkClass={classes.activePage}
            itemClass={classes.numbers}
            linkClass={classes.numbers}
            onChange={handlePageChange}
          />
        </div>
      </div>
      {
        showTrasactionDetailsCard ?
          <TransactionDetailsCard
            datetime={dateFormat(selected_order.datetime, "mmmm dd, yyyy HH:MM")}
            order_number={selected_order.number}
            customer={selected_order.customer}
            amount={selected_order.amountPurchasedCents || selected_order.amountSoldCents}
            paid_price={selected_order.amountPaidCents}
            currency={selected_order.amountPurchasedCurrency || selected_order.amountSoldCurrency}
            paid_currency={selected_order.amountPaidCurrency}
            rate={selected_order.rate}
            user={selected_order.portfolio.user}
            document={selected_order.portfolio.user.document}
            service_point={selected_order.portfolio.user.servicePoint}
            transactionSelectedIndex={transactionSelectedIndex}
            transactionSelectedType="in-store"
          />
          : null
      }
    </div>

  )
}

export default CashManager;
