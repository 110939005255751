import React from 'react';
import classes from './CashManager.module.scss';
import NumberFormat from 'react-number-format';


const InStoreTransactionCard = (props) => {

    const type = props.type;
    const index = props.index + 1;
    const transactionSelectedIndex = props.transactionSelectedIndex;
    let cardStyle;

    const card_color = props.type === "purchase" ? '#CCCCCC' : "rgba(204, 204, 204, 0.28)"

    cardStyle = {
        background: 'white',
        borderLeftColor: card_color
    }
    if (index === transactionSelectedIndex) {
        cardStyle.background = '#F0F0F0';
    }

    return (
        <div className={classes.TransactionCard}
            style={cardStyle}
            onClick={() => props.showDetails(type, index, props.order_id)}>
            <div className={classes.FirstRow}>
                <span className={["fa-stack", classes.UserIcon].join(' ')}>
                    <i className="fas fa-circle fa-stack-2x" style={{ color: card_color }}></i>
                    <i className="fas fa-user fa-stack-1x fa-inverse" style={{ fontSize: '16px' }}></i>
                </span>
                <span>{props.customer}</span>
                <span style={{ marginLeft: 'auto' }}>
                    {type === 'purchase' ? 'Purchased: ' : 'Sold: '}{props.datetime}</span>
            </div>
            <div style={{ fontSize: 20 }}>
                <span>
                    <NumberFormat displayType={"text"} thousandSeparator={true} suffix={" " + props.currency} value={props.amount} />
                </span>
                <span style={{ float: "right" }}>
                    <NumberFormat displayType={"text"} thousandSeparator={true} suffix={" " + props.paid_currency} value={props.paid_price} />
                </span>
            </div>
            <div style={{ fontSize: 12 }}>
                <span>Registration ID: {props.order_number}</span>
                <span style={{ float: "right" }}>
                    {type === 'purchase' ? 'Purchase rate: ' : 'Sales rate: '}{props.rate}</span>
            </div>
        </div>
    )
}

export default InStoreTransactionCard;
