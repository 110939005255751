import React, { Component } from 'react';
import classes from './FilterModal.module.scss';
import CheckSolid from '../../../../assets/icons/check-solid.svg';
import MinusSolid from '../../../../assets/icons/minus-solid.svg';
import CalendarComponent from '../../../UI/Modals/SettingsCalendar/Calendar';

var dateFormat = require('dateformat');

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_option: 0,
      currency: null,
      date_input_type: null,
      calendar_date: new Date(),
      minDate: "",
      maxDate: "",
      filter_object: this.props.filters || {},
      active: this.props.filters.state === "active" ? true : false,
      suspended: this.props.filters.state === "suspended" ? true : false,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.offsetParent.className !== 'filterModalIcon') {
      this.props.closeModal();
    }
  }

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  handleInput = (e) => {
    const input_data = e.target
    this.setState(prevState => {
      let filter_object = Object.assign({}, prevState.filter_object);
      filter_object[input_data.name === undefined ? input_data.getAttribute('name') : input_data.name] = input_data.value === undefined ? input_data.getAttribute('value') : input_data.value;
      return { filter_object };
    })
  }

  handleDate = (type) => {
    this.setState({
      date_input_type: type,
      calendar_active: true
    })
  }

  onChangeCalendar = (date) => {
    let e = null
    let formated_date = dateFormat(date, "dd/mm/yyyy")

    if (this.state.date_input_type === "minDate") {
      e = { target: { value: formated_date, name: "minDate" } }
      this.setState({ minDate: formated_date })
    }
    else {
      e = { target: { value: formated_date, name: "maxDate" } }
      this.setState({ maxDate: formated_date })
    }

    this.handleInput(e)
    this.setState({
      calendar_date: date,
      calendar_active: false

    })
  }

  status_filter = (e) => {
    if (e.target.getAttribute('value') === "active") {
      this.setState({
        active: true,
        suspended: false
      })
    }
    else {
      this.setState({
        active: false,
        suspended: true
      })
    }
  }

  onSave = async () => {
    this.props.getData(this.state.filter_object)
    this.props.closeModal()
  }

  getValue = (value) => {
    if (value === undefined) {
      return ""
    }
    else {
      return value
    }
  }

  resetFilters = () => {
    this.setState({
      filter_object: {},
      minDate: "",
      maxDate: "",
      active: false,
      suspended: false,
      calendar_date: new Date(),
    })
  }

  render() {
    return (
      <div ref={this.setWrapperRef} className={[classes.ModalItem, classes.FilterModal].join(' ')} onClick={this.childEvent.bind(this)} >
        <div className={classes.ModalHeader}>
          <div className={classes.FilterModalHeader}>FILTERS</div>
          <div className={classes.FilterModalHeaderIcon} onClick={this.resetFilters}>
            <i className="fas fa-undo-alt" style={{ color: "white", fontSize: "23px", marginRight: "10px" }}></i>
          </div>
        </div>

        <div className={[classes.ModalBody, classes.FilterModalBody].join(' ')}>
          <div className={classes.FilterItem}>
            <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Status</p> </div>
            <div className={classes.Currencies}>
              <div className={classes.StatusGreen} style={{ opacity: this.state.active ? null : 0.5 }}><img src={CheckSolid} alt="check-solid" name="state" value="active" onClick={(e) => { this.handleInput(e); this.status_filter(e) }} /></div>
              <div className={classes.StatusYellow} style={{ opacity: this.state.suspended ? null : 0.5 }}><img src={MinusSolid} alt="minus-solid" name="state" value="suspended" onClick={(e) => { this.handleInput(e); this.status_filter(e) }} /></div>
            </div>
          </div>

          <div className={classes.FilterItem}>
            <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Value</p></div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="min" name="minValue" value={this.getValue(this.state.filter_object.minValue)} onChange={(e) => this.handleInput(e)} />
              <input type="text" className={classes.Inputs} placeholder="max" name="maxValue" value={this.getValue(this.state.filter_object.maxValue)} onChange={(e) => this.handleInput(e)} />
            </div>
          </div>


          <div className={classes.FilterItem}>
            <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Date opened</p></div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="00/00/0000" name="minDate" value={this.state.filter_object.minDate || this.state.minDate} onClick={() => this.handleDate("minDate")} readOnly={true} />
              <input type="text" className={classes.Inputs} placeholder="00/00/0000" name="maxDate" value={this.state.filter_object.maxDate || this.state.maxDate} onClick={() => this.handleDate("maxDate")} readOnly={true} />
            </div>
          </div>

          {this.state.calendar_active ?
            <div className="filter_calendar">
              <CalendarComponent
                onChange={this.onChangeCalendar}
                value={this.state.calendar_date}
              />
            </div>
            : null}

          <div className={classes.CenterButtons}>
            <button className={classes.YellowButton} onClick={() => this.onSave()}> SAVE </button>
            <button className={classes.WhiteButton} onClick={() => this.props.closeModal()}> CANCEL </button>
          </div>
        </div>

      </div>
    );
  }
}
export default FilterModal;
