import gql from 'graphql-tag';

export default gql`
mutation directUpload($input: DirectUploadInput!) {
  directUpload(input: $input) {
    url
    blobId
    filename
    headers
    signedBlobId
  }
}
`;
