import matching_orders from '../../../queries/CurrencyTrader/MatchingOrders';
import CountCurrencyTrader from '../../../queries/CurrencyTrader/CountCurrencyTrader';
import { API } from 'aws-amplify'
import Amplify from 'aws-amplify';

Amplify.configure({
    API: {
        graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
        graphql_headers: async () => ({
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
    }
});

export async function CurrencyTraderQuery(args, offset) {
    let order_list = await API.graphql({
        query: matching_orders,
        variables: {
            status: "completed",
            attributes: {
                admin: true,
                accountName: args ? args.filter_object.accountName : null,
                maxAmount: args ? args.filter_object.maxValue : null,
                minAmount: args ? args.filter_object.minValue : null,
                maxRateValue: args ? args.filter_object.maxRateValue : null,
                minRateValue: args ? args.filter_object.minRateValue : null,
                minPaidValue: args ? args.filter_object.minPaidValue : null,
                maxPaidValue: args ? args.filter_object.maxPaidValue : null,
                minDate: args ? args.filter_object.minDate : null,
                maxDate: args ? args.filter_object.maxDate : null,
                currency: args ? args.selected_currencies : null,
                sortingElement: args ? args.filter_object.sortingElement : null,
                sortingOrder: args ? args.sorting_order : null,
                offset: offset
            },
        }
    })

    return (
        order_list
    )
}

export async function CounterQuery(args, offset) {
    let counter = await API.graphql({
        query: CountCurrencyTrader,
        variables: {
            status: "completed",
            attributes: {
                admin: true,
                accountName: args ? args.filter_object.accountName : null,
                maxAmount: args ? args.filter_object.maxValue : null,
                minAmount: args ? args.filter_object.minValue : null,
                maxRateValue: args ? args.filter_object.maxRateValue : null,
                minRateValue: args ? args.filter_object.minRateValue : null,
                minPaidValue: args ? args.filter_object.minPaidValue : null,
                maxPaidValue: args ? args.filter_object.maxPaidValue : null,
                minDate: args ? args.filter_object.minDate : null,
                maxDate: args ? args.filter_object.maxDate : null,
                currency: args ? args.selected_currencies : null,
                sortingElement: args ? args.filter_object.sortingElement : null,
                sortingOrder: args ? args.sorting_order : null,
                offset: offset
            }
        }
    })
    return (
        counter
    )
}
