import gql from 'graphql-tag';

export default gql`

mutation ($id:ID!) {
  resendEmailConfirmation(id:$id){
    success
  }   
}

`;
