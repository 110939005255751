import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import classes from "./App.module.scss"
import Login from "../src/components/Login/Login";
import WelcomePage from "../src/components/WelcomePage/WelcomePage";
import Dashboard from '../src/components/Dashboard/Dashboard';
import PrivateRoute from "./PrivateRoute";
import query from './queries/LoggedUser';
import { graphql } from 'react-apollo';
import UserManagement from './components/Dashboard/UserManagement/MainPage';


const App = ({ data: { loading, error, currentUser } }) => {
  return (
    <div className={classes.App}>
      <BrowserRouter>
        {!loading ?
          <Switch>

            <PrivateRoute path="/welcome" currentUser={currentUser} component={WelcomePage} />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/dashboard" currentUser={currentUser} component={Dashboard} />
            <PrivateRoute path="/userManagement" currentUser={currentUser} component={UserManagement} />
            {/* <PrivateRoute path="/dashboard/accounts" currentUser={users} component={Accounts} />
            <PrivateRoute path="/dashboard/cashManager" currentUser={users} component={CashManager} />
            <PrivateRoute path="/dashboard/tradingBook" currentUser={users} component={TradingBook} />
            <PrivateRoute path="/dashboard/limitsAndSpreads" currentUser={users} component={LimitsAndSpreads} /> */}

            <Redirect from="/" to="/dashboard" currentUser={currentUser} />
          </Switch>
          : null}

      </BrowserRouter>
    </div>

  );
}


export default graphql(query)(App);
