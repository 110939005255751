import gql from 'graphql-tag';

export default gql`
{
  roles {
    id
    name
    permissions
    users{
      id
      firstName
      lastName
      personalIdNumber
      address
      city
      country
      postalCode
      email
      cellphone
      fullControl
      role {
        name
        id
      }
    }
  }
}
`;
