import gql from 'graphql-tag';

export default gql`
mutation changeOnlineOrderStatus($numbers: [String!]!, $paid_status: Boolean!) {
  changeOnlineOrderStatus(numbers: $numbers, paidStatus: $paid_status) {
    success
  }
}
`;

// {
//   "numbers": ["7897037361871"],
//   "paid_status": false,
//   "direction": "incoming"
// }
