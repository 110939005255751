import IncomingPayments from '../../../queries/Payments/IncomingPayments';
import CountCurrencyTrader from '../../../queries/CurrencyTrader/CountCurrencyTrader';
import Users from '../../../queries/Users';
import { API } from 'aws-amplify'
import Amplify from 'aws-amplify';

Amplify.configure({
    API: {
        graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
        graphql_headers: async () => ({
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
    }
});

export async function IncomingPaymentsQuery(args, offset) {
  let payment_list = await API.graphql({
      query: IncomingPayments,
      variables: {
          status: "completed",
          attributes: {
              currency: args ? args.currency : null,
              banks: args ? args.banks : null,
              // offset: offset,
              // accountName: args ? args.accountName : null,
              iban: args ? args.iban : null,
              incoming: args.incoming ? args.incoming : null,
              sortingElement: args.sortingElement ? args.sortingElement : null,
              sortingOrder: args.sortingOrder ? args.sortingOrder : null,
              counterQuery: true,
              defaulted: args.defaulted != null ? args.defaulted : null ,
              paidToPortal: args.paidToPortal != null ? args.paidToPortal : null ,
          },
      }
  })

  return (
      payment_list
  )
}



export async function CounterQuery(variables) {
  let cash_manager_counter = await API.graphql({
    query: CountCurrencyTrader,
    variables: { status:'completed', attributes: variables }
  })
  return (
    cash_manager_counter
  )
}


export async function UsersQuery(variables) {
  let users = await API.graphql({
    query: Users,
    variables: {
      attributes: {
        accountName: variables.accountName ? variables.accountName : null,
      }
    }
  })
  return (
    users
  )
}
