import gql from 'graphql-tag';

export default gql`
query($start_date:String, $end_date:String){
  profitLoss(startDate:$start_date, endDate:$end_date){
		currency
    profit
  }
}
`;
