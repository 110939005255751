import React, { Component } from 'react';
import classes from './CalendarFilterModal.module.scss';
import DatePicker from "react-datepicker";

import "./react-datepicker.css";

class CalendarFilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
    * close modal if clicked on outside of element
    */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.offsetParent.className !== 'filterModalIcon') {
            this.props.closeModal();
        }
    }

    childEvent(event) {
        event.stopPropagation();
        if (this.props.childEvent) {
            this.props.childEvent();
        }
    }

    handleStartDateChange = date => {
        this.setState({
            startDate: date
        });
    };

    handleEndDateChange = date => {
        this.setState({
            endDate: date
        });
    };

    popperModifiers = {
        flip: {
            enabled: false
        },
        preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
        }
    };

    render() {

        return (
            <div ref={this.setWrapperRef} className={[classes.ModalItem, classes.FilterModal].join(' ')} onClick={this.childEvent.bind(this)} >
                <div className={classes.ModalHeader}>
                    <div className={classes.FilterModalHeader}>FILTER BY DATE</div>
                </div>

                <div className={[classes.ModalBody, classes.FilterModalBody].join(' ')}>
                    <div style={{ display: "flex" }}>
                        <DatePicker
                            selected={this.state.startDate}
                            onChange={this.handleStartDateChange}
                            startOpen={true}
                            shouldCloseOnSelect={false}
                            popperPlacement="bottom-start"
                            popperModifiers={this.popperModifiers}
                            dateFormat="dd/MM/yyyy"
                        />


                        <DatePicker
                            selected={this.state.endDate}
                            onChange={this.handleEndDateChange}
                            shouldCloseOnSelect={false}
                            popperPlacement="bottom-end"
                            popperModifiers={this.popperModifiers}
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className={classes.CenterButtons} style={{ height: "100%", alignItems: "flex-end" }}>
                        <button className={classes.YellowButton} onClick={() => this.props.handleCalendarFilter(this.state.startDate, this.state.endDate)}>SAVE</button>
                        <button className={classes.WhiteButton} onClick={() => this.props.closeModal()}> CANCEL </button>
                    </div>
                </div>
            </div>
        );
    }
}
export default CalendarFilterModal;
