import React, { useState, useEffect } from 'react';
import classes from './TradingBook.module.scss';
import FourDots from '../../UI/Icons/FourDots/FourDots';
import SearchEngine from '../../UI/SearchEngine/SearchEngine';
import TransactionCard from './TransactionCard';
import FilterModal from '../Modals/TradingBook/FilterModal';
import Pagination from "react-js-pagination";

var dateFormat = require('dateformat');

const PageColumn = (props) => {

  const [active_page, setActivePage] = useState(1)
  const [orders, setOrders] = useState(props.orders)
  const [search_filtered, setSearchFiltered] = useState(false)
  const [search_value, setSearchValue] = useState(null)

  const handlePageChange = pageNumber => {
    setActivePage(pageNumber)
    props.set_offset((pageNumber - 1) * 7)
    props.closeDetailsCard()
    const scrollOptions = {
      left: 0,
      top: 0,
      behavior: 'smooth'
    }
    window.scrollTo(scrollOptions);
  };

  useEffect(() => {
    setOrders(props.orders)
  }, [props.orders]);

  const filterSearchBox = (value) => {
    props.search_account_name(props.type, value)
  }

  useEffect(() => {
    if (props.searchFilter)
      props.search_account_name(props.type, props.searchFilter)
  }, [props.searchFilter])

  return (
    <div className={classes.PageColumn}>
      <div className={classes.Title}>
        <span>{props.title}</span>

        <div className="filterModalIcon" style={{ position: "relative" }} onClick={() => props.toggleFilterModal(props.type)}>
          <FourDots size={18} dotSize={7.37} />
          {props.showFilterModal['' + props.type] ? <FilterModal closeModal={props.closeFilterModal} filterRecords={(state) => props.filterOrders(props.type, state)} default_values={props.default_values} /> : null}
        </div>

        {props.icon === "link" ?
          <span style={{ marginLeft: "auto" }}>
            <i className={["fas fa-share-alt fa-xs fa-rotate-270", classes.LinkIcon].join(' ')}
              onClick={() => props.handleLinkColumns()}></i> </span> :
          (props.icon === "unlink" ?
            <span style={{ marginLeft: "auto" }}>
              <i className={["fas fa-share-alt fa-xs fa-rotate-90", classes.LinkIcon].join(' ')}
                onClick={() => props.handleLinkColumns()}></i></span> : null)}
      </div>

      <SearchEngine placeholder='Search for an offer' extraStyle={{ margin: "30px 0" }} filter_search_filed={filterSearchBox} value={props.searchFilter} />
      <>
        {orders.map((order, index) => {
          const order_type = (typeof (order.amountPurchasedCurrency) != "undefined" ? "purchase" : "sale")

          return (
            <TransactionCard
              key={index}
              index={index}
              order_id={order.id}
              type={order_type}
              showDetails={(type, index) => props.showDetailsCard(type, index, order.id, active_page, orders, search_filtered, props.type)}
              transactionSelectedIndex={props.transactionSelectedIndex}
              service_point_name={order.portfolio.user.globalName}
              amount={order.availableTradingAmount}
              total_cost={order.amountPaidCents}
              currency={order_type === "purchase" ? order.amountPurchasedCurrency : order.amountSoldCurrency}
              rate={order.rate}
              datetime={dateFormat(order.expiration, "dd/mm/yyyy HH:MM")}
            />
          )
        })}
      </>

      <div className={classes.order_pagination}>
        <Pagination
          activePage={active_page}
          itemsCountPerPage={7}
          totalItemsCount={props.pagination_counter}
          pageRangeDisplayed={9}
          prevPageText={null}
          nextPageText={null}
          activeLinkClass={classes.activePage}
          itemClass={classes.numbers}
          linkClass={classes.numbers}
          onChange={handlePageChange}
        />
      </div>

    </div>


  )
}

export default PageColumn;
