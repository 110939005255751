import gql from 'graphql-tag';

export default gql`
query ($currency: String, $duration: String, $start_date:String, $end_date:String, $typology: String!) {
  currencyDetail(currency: $currency, duration: $duration, startDate:$start_date, endDate:$end_date, typology:$typology) {
    numberOfTransactions
    numberOfPurchases
    numberOfSales
    averageSaleRateEur
    averagePurchaseRateEur
    totalPurchasedEur
    totalSoldEur
  }
}
`;
