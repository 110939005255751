import gql from 'graphql-tag';

export default gql`
query ($user_id: ID, $group_id: ID) {
  spreads(userId: $user_id, groupId: $group_id) {
    id
    saleSpread
    currency
  }
}
`;
