import React from 'react';
import classes from './CurrencyTrader.module.scss';

var accounting = require("accounting");

const BankIcon = (props) => {

    const transaction = props.transaction;
    let bank_currency  = transaction.bankAccounts[0].currency;

    const bankAccount = transaction.bankAccounts[0];
    let img = null;
    const images = require.context('../../../assets/icons/', true);
    img = images('./' + bankAccount.bankName + '.png');

    return (
        <div className={classes.Icon} >
            <img style={{ position: "absolute", cursor: "pointer" }} src={img} alt="Bank Image" />
        </div>);
}

export default BankIcon;
