const currencies = {
    "ALL": {
        "code": "ALL",
        "name": "Albanian Lek",
        "symbol": "L",
        "area": "Europe"
    },
    "EUR": {
        "code": "EUR",
        "name": "Euro",
        "symbol": "€",
        "area": "Europe"
    },
    "USD": {
        "code": "USD",
        "name": "US Dollar",
        "symbol": "$",
        "area": "United States"
    },
    "GBP": {
        "code": "GBP",
        "name": "British Pound",
        "symbol": "£",
        "area": "Great Britain"
    },
    "AUD": {
        "code": "AUD",
        "name": "Australian Dollar",
        "symbol": "$",
        "area": "Australia"
    },
    "CAD": {
        "code": "CAD",
        "name": "Canadian  Dollar",
        "symbol": "$",
        "area": "Canada"
    },
    "CHF": {
        "code": "CHF",
        "name": "Swiss Franc",
        "symbol": "₣",
        "area": "Switzerland"
    },
    "JPY": {
        "code": "JPY",
        "name": "Japanese Yen",
        "symbol": "¥",
        "area": "Japan"
    },
    "SEK": {
        "code": "SEK",
        "name": "Swedish Krona",
        "symbol": "kr",
        "area": "Sweden"
    },
    "NOK": {
        "code": "NOK",
        "name": "Norwegian Krone",
        "symbol": "kr",
        "area": "Norway"
    },
    "DKK": {
        "code": "DKK",
        "name": "Danish Krone",
        "symbol": "kr",
        "area": "Denmark"
    },
    "AED": {
        "code": "AED",
        "name": "UAE Dirham",
        "symbol": "د.إ",
        "area": ""
    },
    "AFN": {
        "code": "AFN",
        "name": "Afghani",
        "symbol": "؋",
        "area": ""
    },
    "AMD": {
        "code": "AMD",
        "name": "Armenian Dram",
        "symbol": "",
        "area": ""
    },
    "ANG": {
        "code": "ANG",
        "name": "Netherlands Antillean Guilder",
        "symbol": "",
        "area": ""
    },
    "AOA": {
        "code": "AOA",
        "name": "Kwanza",
        "symbol": "",
        "area": ""
    },
    "ARS": {
        "code": "ARS",
        "name": "Argentine Peso",
        "symbol": "",
        "area": ""
    },
    "AWG": {
        "code": "AWG",
        "name": "Aruban Florin",
        "symbol": "",
        "area": ""
    },
    "AZN": {
        "code": "AZN",
        "name": "Azerbaijanian Manat",
        "symbol": "",
        "area": ""
    },
    "BAM": {
        "code": "BAM",
        "name": "Convertible Mark",
        "symbol": "",
        "area": ""
    },
    "BBD": {
        "code": "BBD",
        "name": "Barbados Dollar",
        "symbol": "",
        "area": ""
    },
    "BDT": {
        "code": "BDT",
        "name": "Taka",
        "symbol": "",
        "area": ""
    },
    "BGN": {
        "code": "BGN",
        "name": "Bulgarian Lev",
        "symbol": "",
        "area": ""
    },
    "BHD": {
        "code": "BHD",
        "name": "Bahraini Dinar",
        "symbol": "",
        "area": ""
    },
    "BIF": {
        "code": "BIF",
        "name": "Burundi Franc",
        "symbol": "",
        "area": ""
    },
    "BMD": {
        "code": "BMD",
        "name": "Bermudian Dollar",
        "symbol": "",
        "area": ""
    },
    "BND": {
        "code": "BND",
        "name": "Brunei Dollar",
        "symbol": "",
        "area": ""
    },
    "BOB": {
        "code": "BOB",
        "name": "Boliviano",
        "symbol": "",
        "area": ""
    },
    "BOV": {
        "code": "BOV",
        "name": "Mvdol",
        "symbol": "",
        "area": ""
    },
    "BRL": {
        "code": "BRL",
        "name": "Brazilian Real",
        "symbol": "",
        "area": ""
    },
    "BSD": {
        "code": "BSD",
        "name": "Bahamian Dollar",
        "symbol": "",
        "area": ""
    },
    "BTN": {
        "code": "BTN",
        "name": "Ngultrum",
        "symbol": "",
        "area": ""
    },
    "BWP": {
        "code": "BWP",
        "name": "Pula",
        "symbol": "",
        "area": ""
    },
    "BYN": {
        "code": "BYN",
        "name": "Belarussian Ruble",
        "symbol": "",
        "area": ""
    },
    "BZD": {
        "code": "BZD",
        "name": "Belize Dollar",
        "symbol": "",
        "area": ""
    },
    "CDF": {
        "code": "CDF",
        "name": "Congolese Franc",
        "symbol": "",
        "area": ""
    },
    "CHE": {
        "code": "CHE",
        "name": "WIR Euro",
        "symbol": "",
        "area": ""
    },
    "CHW": {
        "code": "CHW",
        "name": "WIR Franc",
        "symbol": "",
        "area": ""
    },
    "CLF": {
        "code": "CLF",
        "name": "Unidad de Fomento",
        "symbol": "",
        "area": ""
    },
    "CLP": {
        "code": "CLP",
        "name": "Chilean Peso",
        "symbol": "",
        "area": ""
    },
    "CNY": {
        "code": "CNY",
        "name": "Yuan Renminbi",
        "symbol": "",
        "area": ""
    },
    "COP": {
        "code": "COP",
        "name": "Colombian Peso",
        "symbol": "",
        "area": ""
    },
    "COU": {
        "code": "COU",
        "name": "Unidad de Valor Real",
        "symbol": "",
        "area": ""
    },
    "CRC": {
        "code": "CRC",
        "name": "Costa Rican Colon",
        "symbol": "",
        "area": ""
    },
    "CUC": {
        "code": "CUC",
        "name": "Peso Convertible",
        "symbol": "",
        "area": ""
    },
    "CUP": {
        "code": "CUP",
        "name": "Cuban Peso",
        "symbol": "",
        "area": ""
    },
    "CVE": {
        "code": "CVE",
        "name": "Cabo Verde Escudo",
        "symbol": "",
        "area": ""
    },
    "CZK": {
        "code": "CZK",
        "name": "Czech Koruna",
        "symbol": "",
        "area": ""
    },
    "DJF": {
        "code": "DJF",
        "name": "Djibouti Franc",
        "symbol": "",
        "area": ""
    },
    "DOP": {
        "code": "DOP",
        "name": "Dominican Peso",
        "symbol": "",
        "area": ""
    },
    "DZD": {
        "code": "DZD",
        "name": "Algerian Dinar",
        "symbol": "",
        "area": ""
    },
    "EGP": {
        "code": "EGP",
        "name": "Egyptian Pound",
        "symbol": "",
        "area": ""
    },
    "ERN": {
        "code": "ERN",
        "name": "Nakfa",
        "symbol": "",
        "area": ""
    },
    "ETB": {
        "code": "ETB",
        "name": "Ethiopian Birr",
        "symbol": "",
        "area": ""
    },
    "FJD": {
        "code": "FJD",
        "name": "Fiji Dollar",
        "symbol": "",
        "area": ""
    },
    "FKP": {
        "code": "FKP",
        "name": "Falkland Islands Pound",
        "symbol": "",
        "area": ""
    },
    "GEL": {
        "code": "GEL",
        "name": "Lari",
        "symbol": "",
        "area": ""
    },
    "GHS": {
        "code": "GHS",
        "name": "Ghana Cedi",
        "symbol": "",
        "area": ""
    },
    "GIP": {
        "code": "GIP",
        "name": "Gibraltar Pound",
        "symbol": "",
        "area": ""
    },
    "GMD": {
        "code": "GMD",
        "name": "Dalasi",
        "symbol": "",
        "area": ""
    },
    "GNF": {
        "code": "GNF",
        "name": "Guinea Franc",
        "symbol": "",
        "area": ""
    },
    "GTQ": {
        "code": "GTQ",
        "name": "Quetzal",
        "symbol": "",
        "area": ""
    },
    "GYD": {
        "code": "GYD",
        "name": "Guyana Dollar",
        "symbol": "",
        "area": ""
    },
    "HKD": {
        "code": "HKD",
        "name": "Hong Kong Dollar",
        "symbol": "",
        "area": ""
    },
    "HNL": {
        "code": "HNL",
        "name": "Lempira",
        "symbol": "",
        "area": ""
    },
    "HRK": {
        "code": "HRK",
        "name": "Kuna",
        "symbol": "",
        "area": ""
    },
    "HTG": {
        "code": "HTG",
        "name": "Gourde",
        "symbol": "",
        "area": ""
    },
    "HUF": {
        "code": "HUF",
        "name": "Forint",
        "symbol": "",
        "area": ""
    },
    "IDR": {
        "code": "IDR",
        "name": "Rupiah",
        "symbol": "",
        "area": ""
    },
    "ILS": {
        "code": "ILS",
        "name": "New Israeli Sheqel",
        "symbol": "",
        "area": ""
    },
    "INR": {
        "code": "INR",
        "name": "Indian Rupee",
        "symbol": "",
        "area": ""
    },
    "IQD": {
        "code": "IQD",
        "name": "Iraqi Dinar",
        "symbol": "",
        "area": ""
    },
    "IRR": {
        "code": "IRR",
        "name": "Iranian Rial",
        "symbol": "",
        "area": ""
    },
    "ISK": {
        "code": "ISK",
        "name": "Iceland Krona",
        "symbol": "",
        "area": ""
    },
    "JMD": {
        "code": "JMD",
        "name": "Jamaican Dollar",
        "symbol": "",
        "area": ""
    },
    "JOD": {
        "code": "JOD",
        "name": "Jordanian Dinar",
        "symbol": "",
        "area": ""
    },
    "KES": {
        "code": "KES",
        "name": "Kenyan Shilling",
        "symbol": "",
        "area": ""
    },
    "KGS": {
        "code": "KGS",
        "name": "Som",
        "symbol": "",
        "area": ""
    },
    "KHR": {
        "code": "KHR",
        "name": "Riel",
        "symbol": "",
        "area": ""
    },
    "KMF": {
        "code": "KMF",
        "name": "Comoro Franc",
        "symbol": "",
        "area": ""
    },
    "KPW": {
        "code": "KPW",
        "name": "North Korean Won",
        "symbol": "",
        "area": ""
    },
    "KRW": {
        "code": "KRW",
        "name": "Won",
        "symbol": "",
        "area": ""
    },
    "KWD": {
        "code": "KWD",
        "name": "Kuwaiti Dinar",
        "symbol": "",
        "area": ""
    },
    "KYD": {
        "code": "KYD",
        "name": "Cayman Islands Dollar",
        "symbol": "",
        "area": ""
    },
    "KZT": {
        "code": "KZT",
        "name": "Tenge",
        "symbol": "",
        "area": ""
    },
    "LAK": {
        "code": "LAK",
        "name": "Kip",
        "symbol": "",
        "area": ""
    },
    "LBP": {
        "code": "LBP",
        "name": "Lebanese Pound",
        "symbol": "",
        "area": ""
    },
    "LKR": {
        "code": "LKR",
        "name": "Sri Lanka Rupee",
        "symbol": "",
        "area": ""
    },
    "LRD": {
        "code": "LRD",
        "name": "Liberian Dollar",
        "symbol": "",
        "area": ""
    },
    "LSL": {
        "code": "LSL",
        "name": "Loti",
        "symbol": "",
        "area": ""
    },
    "LYD": {
        "code": "LYD",
        "name": "Libyan Dinar",
        "symbol": "",
        "area": ""
    },
    "MAD": {
        "code": "MAD",
        "name": "Moroccan Dirham",
        "symbol": "",
        "area": ""
    },
    "MDL": {
        "code": "MDL",
        "name": "Moldovan Leu",
        "symbol": "",
        "area": ""
    },
    "MGA": {
        "code": "MGA",
        "name": "Malagasy Ariary",
        "symbol": "",
        "area": ""
    },
    "MKD": {
        "code": "MKD",
        "name": "Denar",
        "symbol": "",
        "area": ""
    },
    "MMK": {
        "code": "MMK",
        "name": "Kyat",
        "symbol": "",
        "area": ""
    },
    "MNT": {
        "code": "MNT",
        "name": "Tugrik",
        "symbol": "",
        "area": ""
    },
    "MOP": {
        "code": "MOP",
        "name": "Pataca",
        "symbol": "",
        "area": ""
    },
    "MRU": {
        "code": "MRU",
        "name": "Ouguiya",
        "symbol": "",
        "area": ""
    },
    "MUR": {
        "code": "MUR",
        "name": "Mauritius Rupee",
        "symbol": "",
        "area": ""
    },
    "MVR": {
        "code": "MVR",
        "name": "Rufiyaa",
        "symbol": "",
        "area": ""
    },
    "MWK": {
        "code": "MWK",
        "name": "Kwacha",
        "symbol": "",
        "area": ""
    },
    "MXN": {
        "code": "MXN",
        "name": "Mexican Peso",
        "symbol": "",
        "area": ""
    },
    "MXV": {
        "code": "MXV",
        "name": "Mexican Unidad de Inversion",
        "symbol": "",
        "area": ""
    },
    "MYR": {
        "code": "MYR",
        "name": "Malaysian Ringgit",
        "symbol": "",
        "area": ""
    },
    "MZN": {
        "code": "MZN",
        "name": "Mozambique Metical",
        "symbol": "",
        "area": ""
    },
    "NAD": {
        "code": "NAD",
        "name": "Namibia Dollar",
        "symbol": "",
        "area": ""
    },
    "NGN": {
        "code": "NGN",
        "name": "Naira",
        "symbol": "",
        "area": ""
    },
    "NIO": {
        "code": "NIO",
        "name": "Cordoba Oro",
        "symbol": "",
        "area": ""
    },
    "NPR": {
        "code": "NPR",
        "name": "Nepalese Rupee",
        "symbol": "",
        "area": ""
    },
    "NZD": {
        "code": "NZD",
        "name": "New Zealand Dollar",
        "symbol": "",
        "area": ""
    },
    "OMR": {
        "code": "OMR",
        "name": "Rial Omani",
        "symbol": "",
        "area": ""
    },
    "PAB": {
        "code": "PAB",
        "name": "Nuevo Sol",
        "symbol": "",
        "area": ""
    },
    "PEN": {
        "code": "PEN",
        "name": "Nuevo Sol",
        "symbol": "",
        "area": ""
    },
    "PGK": {
        "code": "PGK",
        "name": "Kina",
        "symbol": "",
        "area": ""
    },
    "PHP": {
        "code": "PHP",
        "name": "Philippine Peso",
        "symbol": "",
        "area": ""
    },
    "PKR": {
        "code": "PKR",
        "name": "Pakistan Rupee",
        "symbol": "",
        "area": ""
    },
    "PLN": {
        "code": "PLN",
        "name": "Zloty",
        "symbol": "",
        "area": ""
    },
    "PYG": {
        "code": "PYG",
        "name": "Guarani",
        "symbol": "",
        "area": ""
    },
    "QAR": {
        "code": "QAR",
        "name": "Qatari Rial",
        "symbol": "",
        "area": ""
    },
    "RON": {
        "code": "RON",
        "name": "Romanian Leu",
        "symbol": "",
        "area": ""
    },
    "RSD": {
        "code": "RSD",
        "name": "Serbian Dinar",
        "symbol": "",
        "area": ""
    },
    "RUB": {
        "code": "RUB",
        "name": "Russian Ruble",
        "symbol": "",
        "area": ""
    },
    "RWF": {
        "code": "RWF",
        "name": "Rwanda Franc",
        "symbol": "",
        "area": ""
    },
    "SAR": {
        "code": "SAR",
        "name": "Saudi Riyal",
        "symbol": "",
        "area": ""
    },
    "SBD": {
        "code": "SBD",
        "name": "Solomon Islands Dollar",
        "symbol": "",
        "area": ""
    },
    "SCR": {
        "code": "SCR",
        "name": "Seychelles Rupee",
        "symbol": "",
        "area": ""
    },
    "SDG": {
        "code": "SDG",
        "name": "Sudanese Pound",
        "symbol": "",
        "area": ""
    },
    "SGD": {
        "code": "SGD",
        "name": "Singapore Dollar",
        "symbol": "",
        "area": ""
    },
    "SHP": {
        "code": "SHP",
        "name": "Saint Helena Pound",
        "symbol": "",
        "area": ""
    },
    "SLL": {
        "code": "SLL",
        "name": "Leone",
        "symbol": "",
        "area": ""
    },
    "SOS": {
        "code": "SOS",
        "name": "Somali Shilling",
        "symbol": "",
        "area": ""
    },
    "SRD": {
        "code": "SRD",
        "name": "Surinam Dollar",
        "symbol": "",
        "area": ""
    },
    "SSP": {
        "code": "SSP",
        "name": "South Sudanese Pound",
        "symbol": "",
        "area": ""
    },
    "STN": {
        "code": "STN",
        "name": "Dobra",
        "symbol": "",
        "area": ""
    },
    "SVC": {
        "code": "SVC",
        "name": "El Salvador Colon",
        "symbol": "",
        "area": ""
    },
    "SYP": {
        "code": "SYP",
        "name": "Syrian Pound",
        "symbol": "",
        "area": ""
    },
    "SZL": {
        "code": "SZL",
        "name": "Lilangeni",
        "symbol": "",
        "area": ""
    },
    "THB": {
        "code": "THB",
        "name": "Baht",
        "symbol": "",
        "area": ""
    },
    "TJS": {
        "code": "TJS",
        "name": "Somoni",
        "symbol": "",
        "area": ""
    },
    "TMT": {
        "code": "TMT",
        "name": "Turkmenistan New Manat",
        "symbol": "",
        "area": ""
    },
    "TND": {
        "code": "TND",
        "name": "Tunisian Dinar",
        "symbol": "",
        "area": ""
    },
    "TOP": {
        "code": "TOP",
        "name": "Pa’anga",
        "symbol": "",
        "area": ""
    },
    "TRY": {
        "code": "TRY",
        "name": "Turkish Lira",
        "symbol": "",
        "area": ""
    },
    "TTD": {
        "code": "TTD",
        "name": "Trinidad and Tobago Dollar",
        "symbol": "",
        "area": ""
    },
    "TWD": {
        "code": "TWD",
        "name": "New Taiwan Dollar",
        "symbol": "",
        "area": ""
    },
    "TZS": {
        "code": "TZS",
        "name": "Tanzanian Shilling",
        "symbol": "",
        "area": ""
    },
    "UAH": {
        "code": "UAH",
        "name": "Hryvnia",
        "symbol": "",
        "area": ""
    },
    "UGX": {
        "code": "UGX",
        "name": "Uganda Shilling",
        "symbol": "",
        "area": ""
    },
    "USN": {
        "code": "USN",
        "name": "US Dollar(Next day)",
        "symbol": "",
        "area": ""
    },
    "UYI": {
        "code": "UYI",
        "name": "Uruguay Peso en Unidades Indexadas",
        "symbol": "",
        "area": ""
    },
    "UYU": {
        "code": "UYU",
        "name": "Peso Uruguayo",
        "symbol": "",
        "area": ""
    },
    "UZS": {
        "code": "UZS",
        "name": "Uzbekistan Sum",
        "symbol": "",
        "area": ""
    },
    "VEF": {
        "code": "VEF",
        "name": "Bolivar",
        "symbol": "",
        "area": ""
    },
    "VND": {
        "code": "VND",
        "name": "Dong",
        "symbol": "",
        "area": ""
    },
    "VUV": {
        "code": "VUV",
        "name": "Vatu",
        "symbol": "",
        "area": ""
    },
    "WST": {
        "code": "WST",
        "name": "Tala",
        "symbol": "",
        "area": ""
    },
    "XAF": {
        "code": "XAF",
        "name": "CFA Franc BEAC",
        "symbol": "",
        "area": ""
    },
    "XCD": {
        "code": "XCD",
        "name": "East Caribbean Dollar",
        "symbol": "",
        "area": ""
    },
    "XDR": {
        "code": "XDR",
        "name": "Special Drawing Right",
        "symbol": "",
        "area": ""
    },
    "XOF": {
        "code": "XOF",
        "name": "CFA Franc BCEAO",
        "symbol": "",
        "area": ""
    },
    "XPF": {
        "code": "XPF",
        "name": "CFP Franc",
        "symbol": "",
        "area": ""
    },
    "XSU": {
        "code": "XSU",
        "name": "Sucre",
        "symbol": "",
        "area": ""
    },
    "XUA": {
        "code": "XUA",
        "name": "ADB Unit of Account",
        "symbol": "",
        "area": ""
    },
    "YER": {
        "code": "YER",
        "name": "Yemeni Rial",
        "symbol": "",
        "area": ""
    },
    "ZAR": {
        "code": "ZAR",
        "name": "Rand",
        "symbol": "",
        "area": ""
    },
    "ZMW": {
        "code": "ZMW",
        "name": "Zambian Kwacha",
        "symbol": "",
        "area": ""
    },
    "ZWL": {
        "code": "ZWL",
        "name": "Zimbabwe Dollar",
        "symbol": "",
        "area": ""
    },
    "OTHER": {
        "name": "OTHER",
        "symbol": ""
    }
};

export default currencies;