import React, { useState, useEffect } from 'react';
import classes from './Accounts.module.scss';

import CurrencyTraderInfo from './CurrencyTraderInfo';
import UserInfo from './UserInfo/UserInfo';
import AccountingInfo from './AccountingInfo';
import GroupDetails from './GroupDetails';

const DetailsCard = (props) => {

    const [selectedItem, setSelectedItem] = useState(props.selectedItem);
    useEffect(() => {
        setSelectedItem(props.selectedItem);
    }, [props.selectedItem])
    const [showCTInfo, setShowCTInfo] = useState(true);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showAccountingInfo, setShowAccountingInfo] = useState(false);
    const [accountTypology, setAccountTypology] = useState(selectedItem ? selectedItem.typology : "");
    const type = props.type;
    const index = props.columnSelectedIndex;
    let triangleTop;
    let triangleBottom;
    let cardStyle = { top: null };
    let triangleStyle = { top: null, bottom: null };
    if (index <= 5) {
        cardStyle.top = 120;
        triangleTop = 43 + (index - 1) * 120;
        triangleStyle.top = triangleTop;
    }
    else if (showAccountingInfo && index == 6) {
        cardStyle.top = 287;
        triangleBottom = 60 + index * 70;
        triangleStyle.top = triangleBottom;
    }
    else if (showAccountingInfo && index == 7) {
        cardStyle.top = 287;
        triangleBottom = 60 + (index + 1) * 70;
        triangleStyle.top = triangleBottom;
    }
    else {
        cardStyle.top = 287;
        triangleBottom = 50 + (8 - index) * 118;
        triangleStyle.bottom = triangleBottom;
    }

    const handleHeaderOnClick = (section) => {

        if (showCTInfo) setShowCTInfo(!showCTInfo);
        if (showUserInfo) setShowUserInfo(!showUserInfo);
        if (showAccountingInfo) setShowAccountingInfo(!showAccountingInfo);

        switch (section) {
            case 'accounting': {
                setShowAccountingInfo(true);
                break;
            }
            case 'currencyTrader': {
                setShowCTInfo(true);
                break;
            }
            case 'userInfo': {
                setShowUserInfo(true);
                break;
            }
            default:
            //do nothing
        }

    }

    useEffect(() => {
        setAccountTypology(selectedItem.typology);
        if (accountTypology === "market_viewer") {
            setShowUserInfo(true);
            setShowAccountingInfo(false);
            setShowCTInfo(false);
        }
    });

    const accountHeader = (<div className={classes.Header}>
        {accountTypology !== "market_viewer" ?
            (!showAccountingInfo ?
                <>
                    <div className={classes.Item1}
                        style={{ borderBottom: "2px solid #CCCCCC" }}
                        onClick={() => handleHeaderOnClick('accounting')}>ACCOUNTING</div>
                    <div style={{ display: "flex" }}>
                        <div className={showCTInfo ? [classes.Item2, classes.ActiveStyle].join(' ') : classes.Item2}
                            style={{ borderRight: "2px solid #CCCCCC" }}
                            onClick={() => handleHeaderOnClick('currencyTrader')}>CURRENCY TRADER</div>
                        <div className={showUserInfo ? [classes.Item2, classes.ActiveStyle].join(' ') : classes.Item2}
                            onClick={() => handleHeaderOnClick('userInfo')}>USER INFORMATION</div>
                    </div>
                </> :
                <>
                    <div style={{ display: "flex" }}>
                        <div className={showCTInfo ? [classes.Item2, classes.ActiveStyle].join(' ') : classes.Item2}
                            style={{ borderRight: "2px solid #CCCCCC" }}
                            onClick={() => handleHeaderOnClick('currencyTrader')}>CURRENCY TRADER</div>
                        <div className={showUserInfo ? [classes.Item2, classes.ActiveStyle].join(' ') : classes.Item2}
                            onClick={() => handleHeaderOnClick('userInfo')}>USER INFORMATION</div>
                    </div>
                    <div className={[classes.Item1, classes.ActiveStyle].join(' ')}
                        style={{ borderTop: "2px solid #CCCCCC" }}
                        onClick={() => handleHeaderOnClick('accounting')}>ACCOUNTING</div>
                </>) :
            <div className={[classes.Item1, classes.ActiveStyle].join(' ')}
                style={{ borderTop: "2px solid #CCCCCC" }}>USER INFORMATION</div>
        }

    </div>);

    const groupHeader = (
        <div className={classes.Header}>
            <div className={classes.Item1}
                onClick={() => handleHeaderOnClick('accounting')}>
                <span>{(selectedItem && selectedItem.name) && selectedItem.name.toUpperCase()} GROUP MEMBERS</span>
                <i className={["fas fa-pen fa-lg", classes.EditGroupIcon].join(' ')} onClick={props.toggleEditGroup}></i>
            </div>
        </div>);

    return (
        <div className={classes.AccountDetails} style={cardStyle}>
            <div className={classes.Triangle} style={triangleStyle}></div>

            {type === "accountCard" ? accountHeader : groupHeader}

            {(showCTInfo && type === "accountCard" && selectedItem.typology !== "market_viewer") && <CurrencyTraderInfo account={selectedItem} />}
            {(showUserInfo && type === "accountCard") && <UserInfo account={selectedItem} handleShowDetails={props.handleShowDetails} />}
            {(showAccountingInfo && type === "accountCard" && selectedItem.typology !== "market_viewer") && <AccountingInfo account={selectedItem} />}

            {type === "groupCard" && <GroupDetails selectedItem={selectedItem} />}
        </div >
    )
}

export default DetailsCard;
