import React, { useState, useEffect, useRef } from 'react';
import classes from '../LimitsAndSpreads.module.scss';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import users_query from '../../../../queries/Users'
import groups_query from '../../../../queries/Groups/Groups'
import spreads_query from '../../../../queries/Spreads/Spreads'
import update_spreads from '../../../../mutations/Spreads/CreateSpread'
import FilterResults from 'react-filter-search';
import { API, graphqlOperation } from 'aws-amplify'
import Amplify from 'aws-amplify';

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
});

const NewSpreadScreen = (props, ref) => {

  const [users_list, setUsersList] = useState([])
  const [selected_user, setSelectedUser] = useState(null)
  const [selected_type, setSelectedType] = useState(null)
  const [search_value, setSearchValue] = useState("")
  const [list_opened, setListOpened] = useState(false)
  const [spreads_data, setSpreads] = useState(null)
  const [EUR, set_EUR] = useState("")
  const [USD, set_USD] = useState("")
  const [GBP, set_GBP] = useState("")
  const [CHF, set_CHF] = useState("")

  const { loading, error, data, refetch } = useQuery(users_query);
  const { loading: loading_grp, error: error_grp, data: data_grp, refetch: refetch_grp } = useQuery(groups_query);

  const [updateSpread, { data_mutation }] = useMutation(update_spreads, {
    refetchQueries: [{ query: spreads_query }, { query: users_query }]
  })

  useEffect(() => {
    refetch()
  }, [])

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setListOpened(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (loading || loading_grp) return 'Loading...';
  if (error || error_grp) return `Error! ${error.message}`;
  let users = []

  data.users.forEach(user => {
    users = [...users, { name: user.globalName, id: user.id, type: "individual"}]
  })
  data_grp.groups.forEach(group => {
    users = [...users, { name: group.name, id: group.id, type: "group" }]
  })

  if (users_list.length === 0) {
    setUsersList(users)
  }

  const filterList = (e) => {
    setSearchValue(e.target.value)
  }


  const onSelectUser = async (user) => {
    setListOpened(false)
    setSearchValue(user.name)
    setSelectedUser(user.id)
    setSelectedType(user.type)

    try {
      const fetched_data = await API.graphql(graphqlOperation(spreads_query, {
        user_id: user.type === "individual" ? user.id : null,
        group_id: user.type === "group" ? user.id : null
      }))
      setSpreads(fetched_data.data.spreads)
      fetched_data.data.spreads.forEach(spread => {
        if (eval(spread.currency) == null || user.id !== selected_user) {
          eval("set_" + spread.currency)(spread.saleSpread)
        }
      })
    } catch (err) {
    }
  }


  const saveSpreads = () => {
    spreads_data.forEach(spread => {
      updateSpread({
        variables: {
          user_id: selected_type === "individual" ? selected_user : null,
          group_id: selected_type === "group" ? selected_user : null,
          attributes: {
            saleSpread: eval(spread.currency),
            currency: spread.currency
          }
        }
      }).then(res => {
        refetch()
        refetch_grp()
        props.toggleAdd()
      })
    })
  }


  return (
    <div className={classes.ColumnContent}>
      <table className={classes.editSpreadsExceptionsForm}>
        <tbody>
          <tr>
            <td colSpan="3" style={{ position: "relative" }}>
              <input style={{ width: "100%", "textAlign": "left" }}
                className={classes.TextInput}
                type="text"
                value={search_value}
                onClick={() => setListOpened(true)}
                onChange={(e) => { filterList(e) }}
                placeholder="Select user or group"
              />

              {list_opened ?
                <div ref={wrapperRef} className={classes.SearchContainer}>
                  <FilterResults
                    value={search_value}
                    data={users_list}
                    renderResults={results => (
                      <div>
                        {results.map((user, index) => (
                          <div key={index} className={classes.searchBoxElement} onClick={() => onSelectUser(user)}>
                            {user.name}
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
                :
                null}

            </td>
          </tr>
          <tr>
            <td><b>EUR</b></td>
            <td><input className={classes.TextInput} type="text" value={EUR} onChange={(e) => set_EUR(e.target.value)} placeholder={"0,00"} /></td>
          </tr>
          <tr>
            <td><b>USD</b></td>
            <td><input className={classes.TextInput} type="text" value={USD} onChange={(e) => set_USD(e.target.value)} placeholder={"0,00"} /></td>
          </tr>
          <tr>
            <td><b>GBP</b></td>
            <td><input className={classes.TextInput} type="text" value={GBP} onChange={(e) => set_GBP(e.target.value)} placeholder={"0,00"} /></td>
          </tr>
          <tr>
            <td><b>CHF</b></td>
            <td><input className={classes.TextInput} type="text" value={CHF} onChange={(e) => set_CHF(e.target.value)} placeholder={"0,00"} /></td>
          </tr>
        </tbody>
      </table>

      <div style={{ paddingRight: "30px", display: "flex", marginLeft: "auto" }}>
        <button className={classes.YellowButton} onClick={saveSpreads}>SAVE</button>
        <button className={classes.WhiteButton} onClick={props.toggleAdd}>CANCEL</button>
      </div>
    </div>
  );
}

export default NewSpreadScreen;
