import React, { Component } from 'react';
import classes from './FilterModal.module.scss';
import MultiSelectDropdown from '../../../UI/Dropdown/MultiSelectDropdown';
import CalendarComponent from '../../../UI/Modals/SettingsCalendar/Calendar';

var dateFormat = require('dateformat');

class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef()
    this.currencies = [
      { value: "EUR", name: "EUR (EUROS)", sign: "€", isChecked: false },
      { value: "USD", name: "USD (US dollars)", sign: "$", isChecked: false },
      { value: "GBP", name: "GBP (British pounds)", sign: "£", isChecked: false },
      { value: "CHF", name: "CHF (Swiss francs)", sign: "₣", isChecked: false },
      { value: "CAD", name: "CAD (Canadian Dollars)", sign: "₣", isChecked: false },
      { value: "AUD", name: "AUD (Australian Dollars)", sign: "₣", isChecked: false },
      { value: "JPY", name: "JPY (Japanese Yen)", sign: "₣", isChecked: false },
      { value: "SEK", name: "SEK (Swedish Krona)", sign: "₣", isChecked: false },
      { value: "NOK", name: "NOK (Norwegian Krone)", sign: "₣", isChecked: false },
      { value: "DKK", name: "DKK (Danish Krone)", sign: "₣", isChecked: false }
    ]

    this.state = {
      active_option: 0,
      currency: null,
      filter_object: props.attributes || {},
      selectedList: props.attributes ?
        props.attributes.currency ?
          this.currencies.filter(obj => props.attributes.currency.includes(obj.value)) :
          [] :
        [],
      calendar_active: false,
      date_input_type: null,
      calendar_date: new Date(),
      minDate: "",
      maxDate: "",
      sorting_icon: "fas fa-sort-down fa-2x",
      sorting_order: "DESC"

    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.srcElement.offsetParent.className !== 'filterModalIcon') {
      this.props.closeModal();
    }
  }

  childEvent(event) {
    event.stopPropagation();
    if (this.props.childEvent) {
      this.props.childEvent();
    }
  }

  onSelectDropdown(selectedValue, id) {
    var value = selectedValue.slice(0, 3);
    this.setState({ currency: value });
  }

  handleInput = (e) => {
    const input_data = e.target
    this.setState(prevState => {
      let filter_object = Object.assign({}, prevState.filter_object);
      filter_object[input_data.name === undefined ? input_data.getAttribute('name') : input_data.name] = input_data.value === undefined ? input_data.getAttribute('value') : input_data.value;
      return { filter_object };
    })
  }

  onSelect = (selectedList) => {
    let currencies_list = []
    selectedList.forEach(obj => {
      currencies_list.push(obj.value)
    })
    this.setState(prevState => {
      let filter_object = Object.assign({}, prevState.filter_object);
      filter_object.currency = currencies_list.length > 0 ? currencies_list : null;
      return { filter_object };
    })
  }

  handleDate = (type) => {
    this.setState({
      date_input_type: type,
      calendar_active: true
    })
  }

  onChangeCalendar = (date) => {
    let e = null
    let formated_date = dateFormat(date, "dd/mm/yyyy")

    if (this.state.date_input_type === "minDate") {
      e = { target: { value: formated_date, name: "minDate" } }
      this.setState({ minDate: formated_date })
    }
    else {
      e = { target: { value: formated_date, name: "maxDate" } }
      this.setState({ maxDate: formated_date })
    }

    this.handleInput(e)
    this.setState({
      calendar_date: date,
      calendar_active: false

    })
  }

  resetFilters = () => {
    this.setState({
      filter_object: {},
      minDate: "",
      maxDate: "",
      calendar_date: new Date(),
    })
    this.child.current.resetValues();
  }

  getValue = (value) => {
    if (value === undefined) {
      return ""
    }
    else {
      return value
    }
  }

  changeSortingIcon = (e) => {
    e.stopPropagation();
    if (this.state.sorting_icon === "fas fa-sort-down fa-2x") {
      this.setState({
        sorting_icon: "fas fa-sort-up fa-2x",
        sorting_order: "ASC"
      })
    }
    else {
      this.setState({
        sorting_icon: "fas fa-sort-down fa-2x",
        sorting_order: "DESC"
      })
    }
  }

  onSave = async () => {
    if (this.state.filter_object.sortingElement !== undefined) {
      await this.handleInput({ target: { name: "sortingOrder", value: this.state.sorting_order } })
    }
    this.props.getData(this.state.filter_object)
    this.props.closeModal()
  }

  render() {
    const sort_element = <i className={[this.state.sorting_icon, classes.SortIcon].join(' ')} style={this.state.sorting_icon === "fas fa-sort-up fa-2x" ? { marginTop: "7px" } : null} onClick={this.changeSortingIcon}></i>

    return (

      <div ref={this.setWrapperRef} className={[classes.ModalItem, classes.FilterModal].join(' ')} onClick={this.childEvent.bind(this)} >
        <div className={classes.ModalHeader}>
          <div className={classes.FilterModalHeader}>FILTERS</div>
          <div className={classes.FilterModalHeaderIcon}>
            <i className="fas fa-undo-alt" style={{ color: "white", fontSize: "23px", marginRight: "10px", cursor: "pointer" }} onClick={() => this.resetFilters()}></i>
          </div>
        </div>

        <div className={[classes.ModalBody, classes.FilterModalBody].join(' ')}>
          <div>
            <div className={classes.ItemHeader}> <p className={classes.ItemTitle}>Currency</p> </div>
            <MultiSelectDropdown
              options={this.currencies}
              selectedValues={this.state.selectedList}
              onSelect={this.onSelect}
              onRemove={this.onSelect}
              closeOnSelect={false}
              ref={this.child}
            />

          </div>
          <div>

            <div className={classes.ItemHeader} name="sortingElement" value="value" onClick={(e) => this.handleInput(e)}>
              <p className={classes.ItemTitle}>Value</p>
              {this.state.filter_object.sortingElement === "value" ? sort_element : null}
            </div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="Min" name="minValue" value={this.getValue(this.state.filter_object.minValue)} onChange={(e) => this.handleInput(e)} />
              <input type="text" className={classes.Inputs} placeholder="Max" name="maxValue" value={this.getValue(this.state.filter_object.maxValue)} onChange={(e) => this.handleInput(e)} />
            </div>
          </div>
          <div>
            <div className={classes.ItemHeader} name="sortingElement" value={"amount_paid_cents"} onClick={(e) => this.handleInput(e)}>
              <p className={classes.ItemTitle}>Price paid (L)</p>
              {this.state.filter_object.sortingElement === "amount_paid_cents" ? sort_element : null}
            </div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="Min" name="minPaidValue" value={this.getValue(this.state.filter_object.minPaidValue)} onChange={(e) => this.handleInput(e)} />
              <input type="text" className={classes.Inputs} placeholder="Max" name="maxPaidValue" value={this.getValue(this.state.filter_object.maxPaidValue)} onChange={(e) => this.handleInput(e)} />
            </div>
          </div>
          <div>
            <div className={classes.ItemHeader} name="sortingElement" value={"rate"} onClick={(e) => this.handleInput(e)}>
              <p className={classes.ItemTitle}>Rate</p>
              {this.state.filter_object.sortingElement === "rate" ? sort_element : null}
            </div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="Min" name="minRateValue" value={this.getValue(this.state.filter_object.minRateValue)} onChange={(e) => this.handleInput(e)} />
              <input type="text" className={classes.Inputs} placeholder="Max" name="maxRateValue" value={this.getValue(this.state.filter_object.maxRateValue)} onChange={(e) => this.handleInput(e)} />
            </div>
          </div>

          <div>
            <div className={classes.ItemHeader} name="sortingElement" value={"datetime"} onClick={(e) => this.handleInput(e)}>
              <p className={classes.ItemTitle}>Date</p>
              {this.state.filter_object.sortingElement === "datetime" ? sort_element : null}
            </div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="00/00/0000" name="minDate" value={this.state.filter_object.minDate || this.state.minDate} onClick={() => this.handleDate("minDate")} readOnly={true} />
              <input type="text" className={classes.Inputs} placeholder="00/00/0000" name="maxDate" value={this.state.filter_object.maxDate || this.state.maxDate} onClick={() => this.handleDate("maxDate")} readOnly={true} />
            </div>
          </div>
          {this.state.calendar_active ?
            <div className="filter_calendar">
              <CalendarComponent
                onChange={this.onChangeCalendar}
                value={this.state.calendar_date}
              />
            </div>
            : null}

          <div>
            <div className={classes.ItemHeader}>
              <p className={classes.ItemTitle}>Account Name</p>
            </div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="Name" name="accountName" value={this.getValue(this.state.filter_object.accountName)} onChange={(e) => this.handleInput(e)} />
            </div>
          </div>

          <div>
            <div className={classes.ItemHeader}>
              <p className={classes.ItemTitle}>Client</p>
            </div>
            <div className={classes.Currencies}>
              <input type="text" className={classes.Inputs} placeholder="Name" name="client" value={this.getValue(this.state.filter_object.client)} onChange={(e) => this.handleInput(e)} />
            </div>
          </div>

          <div className={classes.CenterButtons}>
            <button className={classes.YellowButton} onClick={() => this.onSave()}>SAVE</button>
            <button className={classes.WhiteButton} onClick={() => this.props.closeModal()}> CANCEL </button>
          </div>
        </div>

      </div>
    );
  }
}
export default FilterModal;
