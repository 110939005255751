import React, { useState, useEffect, useRef } from 'react';
import classes from '../LimitsAndSpreads.module.scss';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import users_query from '../../../../queries/Users'
import update_limits from '../../../../mutations/Limits/UpdateLimit'
import FilterResults from 'react-filter-search';
import Amplify from 'aws-amplify';
import NumberFormat from 'react-number-format';

Amplify.configure({
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
    graphql_headers: async () => ({
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    })
  }
});

const NewLimitScreen = (props, ref) => {

  const [users_list, setUsersList] = useState([])
  const [selected_user, setSelectedUser] = useState(null)
  const [search_value, setSearchValue] = useState("")
  const [list_opened, setListOpened] = useState(false)
  const [new_limit, setNewLimit] = useState(null)

  const { loading, error, data } = useQuery(users_query);
  const [updateLimit] = useMutation(update_limits, { refetchQueries: [{ query: users_query }] })


  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setListOpened(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  let users = []

  data.users.forEach(user => {
    users = [...users, { name: user.globalName, id: user.id, tradingDailyLimit: user.tradingDailyLimit}]
  })
  if (users_list.length === 0) {
    setUsersList(users)
  }
  const filterList = (e) => {
    setSearchValue(e.target.value)
  }

  const onSelectUser = (user) => {
    setListOpened(false)
    setSearchValue(user.name)
    setSelectedUser(user.id)

    setNewLimit(user.tradingDailyLimit)
  }


  const updateLimitMutation = () => {
    if (selected_user == null || new_limit == null) {
      return
    }
    else {
      updateLimit({
        variables: { user_id: selected_user, limit: parseInt(new_limit) }
      }).then(res => {
        props.toggleAdd()
      })
    }
  }

  const onValueChange = (limit_value) => {
    setNewLimit(limit_value)
  }


  return (

    <div className={classes.ColumnContent}>
      <table className={classes.editSpreadsExceptionsForm} style={{ borderSpacing: "20px" }}>
        <tbody>
          <tr>
            <td colSpan="3" style={{ position: "relative", width: "70%" }}>
              <input style={{ width: "100%", "textAlign": "left" }}
                className={classes.TextInput}
                type="text"
                value={search_value}
                onClick={() => setListOpened(true)}
                onChange={(e) => { filterList(e) }}
                placeholder="Select user or group"
              />

              {list_opened ?
                <div ref={wrapperRef} className={classes.SearchContainer}>
                  <FilterResults
                    value={search_value}
                    data={users_list}
                    renderResults={results => (
                      <div>
                        {results.map(user => (
                          <div key={user.id} className={classes.searchBoxElement} onClick={() => onSelectUser(user)}>
                            {user.name}
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
                :
                null}
            </td>
            <td colSpan="1" style={{ width: "30%" }}>
              <NumberFormat className={classes.TextInput} thousandSeparator={true} placeholder={"0.00"} value={new_limit} onValueChange={(value) => onValueChange(value.value)} />
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ paddingRight: "30px", display: "flex", marginLeft: "auto" }}>
        <button className={classes.YellowButton} onClick={updateLimitMutation}>SAVE</button>
        <button className={classes.WhiteButton} onClick={props.toggleAdd}>CANCEL</button>
      </div>
    </div>
  );
}

export default NewLimitScreen;
