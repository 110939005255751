import gql from 'graphql-tag';

export default gql`
query ($attributes: FilterCurrencyTraderInput) {
  currencyTraderOrders(attributes: $attributes, status: "completed") {
    ... on PurchaseOrder {
      id
      amountPaidCents
      amountPaidCurrency
      amountPurchasedCents
      amountPurchasedCurrency
      status
      type
      paidToPortal
      datetime
      rate
      number
      CI
      missingPayment
      completedPayment
      lastNotifiedAt
      portfolio {
        user {
          firstName
          lastName
          globalName
          typology
          servicePoint {
            commercialName
          }
          document {
            typology
            issueDate
            issueEntity
            expirationDate
            number
            description
            identityDocumentUrl
            identityDocumentFilename
          }
          tradingDailyLimit
        }
      }
      bankAccounts(type: "incoming") {
        currency
        iban
        bankName
      }
      transactions {
        amount
        saleOrder {
          id
          amountPaidCents
          amountSoldCents
          amountPaidCurrency
          amountSoldCurrency
          paidToPortal
          type
          rate
          number
          datetime
          CI
          missingPayment
          completedPayment
          lastNotifiedAt
          portfolio {
            user {
              firstName
              lastName
              globalName
              typology
              servicePoint {
                commercialName
              }
              document {
                typology
                issueDate
                issueEntity
                expirationDate
                number
                description
                identityDocumentUrl
                identityDocumentFilename
              }
              tradingDailyLimit
            }
          }
          bankAccounts(type: "incoming") {
            currency
            iban
            bankName
          }
        }
      }
    }
    ... on SaleOrder {
      id
      amountPaidCents
      amountSoldCents
      amountPaidCurrency
      amountSoldCurrency
      type
      paidToPortal
      datetime
      rate
      number
      CI
      missingPayment
      completedPayment
      lastNotifiedAt
      portfolio {
        user {
          firstName
          lastName
          globalName
          typology
          servicePoint {
            commercialName
          }
          document {
            typology
            issueDate
            issueEntity
            expirationDate
            number
            description
            identityDocumentUrl
            identityDocumentFilename
          }
          tradingDailyLimit
        }
      }
      bankAccounts(type: "incoming") {
        currency
        iban
        bankName
      }
      transactions {
        amount
        purchaseOrder {
          id
          amountPaidCents
          amountPaidCurrency
          amountPurchasedCents
          amountPurchasedCurrency
          datetime
          rate
          number
          CI
          missingPayment
          completedPayment
          lastNotifiedAt
          paidToPortal
          type
          portfolio {
            user {
              firstName
              lastName
              globalName
              typology
              servicePoint {
                commercialName
              }
              document {
                typology
                issueDate
                issueEntity
                expirationDate
                number
                description
                identityDocumentUrl
                identityDocumentFilename
              }
              tradingDailyLimit
            }
          }
          bankAccounts(type: "incoming") {
            currency
            iban
            bankName
          }
        }
      }
    }
  }
}

`;
