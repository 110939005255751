import gql from 'graphql-tag';

export default gql`
{
  backOfficeUsers {
    id
    firstName
    lastName
    personalIdNumber
    address
    city
    country
    postalCode
    email
    cellphone
    fullControl
    role {
      name
      id
    }
    activityLogs{
      createdAt
    }
  }
}
`;
