import gql from 'graphql-tag';

export default gql`
mutation approveUser($user_id: ID!) {
  approveUser(userId: $user_id) {
    success
  }
}
`;

// Can be used to activate the user also, after he has been suspended
