import React, { useState, useEffect }  from 'react';
import classes from './OutgoingPayments.module.scss';
import IntesaSanPaoloIcon from '../../../assets/icons/ChartsIcons/Intesa San Paolo.png';
import RaiffeisenIcon from '../../../assets/icons/ChartsIcons/Raiffeisen.png';

const DetailsActionCard = (props) => {
  const [selectedDueList, setselectedDueList] = useState({})

  useEffect(() => {

    var item = {}

    for (const [key, value] of Object.entries(props.selectedDue)) {
      let newValue = value.values.filter(x=>x.CI == false).filter(x=>x.paidFromPortal == false)
      if (newValue.length > 0) {
        item[key] = {bank:key, values:newValue}
      }
    }

    setselectedDueList(item)
  }, [props.selectedDue]);


    const BankRow = (props) => {
      var currencies = {};
      var list = props.bank.values.map((index, key)=>{
        if (index.type == "purchase") {
          if (currencies.hasOwnProperty(index.amountPurchasedCurrency)){
            currencies[index.amountPurchasedCurrency].value += parseFloat(index.amountPurchasedCents.replace(".00","").replace(" ","").replace(/\D/, "").replace(",",''))
          }else{
            currencies[index.amountPurchasedCurrency] = {currency: index.amountPurchasedCurrency, value: parseFloat(index.amountPurchasedCents.replace(".00","").replace(" ","").replace(/\D/, "").replace(",",''))};
          }
        }else{
          if (currencies.hasOwnProperty(index.amountPaidCurrency)){
            currencies[index.amountPaidCurrency].value += parseFloat(index.amountPaidCents.replace(".00","").replace(" ","").replace(/\D/, "").replace(",",''))
          }else{
            currencies[index.amountPaidCurrency] = {currency: index.amountPaidCurrency, value: parseFloat(index.amountPaidCents.replace(".00","").replace(" ","").replace(/\D/, "").replace(",",''))};
          }

        }
      })
      var currencies_list = Object.values(currencies).map((index, key)=>{
        return (<div key={key} className={classes.BankRowBody}><span>{index.currency}</span>{index.value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</div>)
      })
      return(
        <div className={classes.BankRow}>
          <div className={classes.BankRowHead}>
            <span style={{bottom:-2, marginRight:5}}>
              {/*<img src={props.bank.logo} alt="Bank Image" />*/}
            </span>
            <span>{props.bank.values[0].bankAccounts[0].bankName}</span>
          </div>
          {currencies_list}
        </div>
      )
    }

    var BankRowList = Object.values(selectedDueList).map((index, key)=>{
      return (<BankRow key={key} bank={index}/>)
    })

    var paidStyle = props.markAsPaidDisable ? ' ' + classes.ButtonDisabled : '';
    var unpaidStyle = props.markAsUnpaidDisable ? ' ' + classes.ButtonDisabled : '';

    return (
        <div className={classes.DetailsActionCard}>
            <div style={{ margin: "20px 0" }}>
                <div style={{ marginBottom: 5 }}><b>AMOUNT DUE: </b></div>
                <div>Select one or more pending payments to see the total amount due</div>
            </div>
            {BankRowList}
            <div style={{ margin: "20px 0" }}>
                <div style={{ marginBottom: 5 }}><b>MAKE A PAYMENT: </b></div>
                <div>Take actions on all of the selected payments using the options bellow</div>
            </div>
            <button className={classes.YellowButton + paidStyle} style={{ width: 180, float: "left", marginLeft: 0, marginRight:10,}} disabled={props.markAsPaidDisable} onClick={()=>{props.markAsPaid()}}>MARK AS PAID</button>
            {props.markAsUnpaidDisplay ? <button className={classes.WhiteButton + unpaidStyle} style={{ width: 180, float: "left", marginLeft: 0, marginRight:10}} disabled={props.markAsUnpaidDisable} onClick={()=>{props.markAsPaid(false)}}>MARK AS UNPAID</button> : null}
        </div>
    );
}

export default DetailsActionCard;
