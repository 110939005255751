import React, { useEffect, useRef } from 'react';
import classes from '../Accounts.module.scss';
import ShareholderComponent from './ShareholderComponent';

const ShareholdersPopup = (props) => {

    const shareholders = props.shareholders;

    const shareholdersHTML = shareholders.map((shareholder, i) => {
        let counter = null
        let selected_circle = null
        const position_length = shareholder.position.toString().length
        if (shareholder.position.toString().charAt(position_length - 1) === "1" && shareholder.position.toString().length !== 1) {
            const sub_shareholders = shareholders.filter(shareholderi => shareholderi.position.toString().startsWith(shareholder.position.toString().substring(0, shareholder.position.toString().length - 1)) && shareholderi.position.toString().length === shareholder.position.toString().length)
            counter = sub_shareholders.length
        }

        const sub_shareholders = shareholders.filter(shareholderi => shareholderi.position.toString().startsWith(shareholder.position) && shareholderi.position.toString().length !== shareholder.position.toString().length).length
        if (sub_shareholders > 0) {
            selected_circle = true
        }

        return (
            <ShareholderComponent
                key={i}
                padding_counter={position_length}
                input_name={shareholder.position}
                shareholders={shareholders}
                name={shareholder.name}
                counter={counter}
                selected_circle={selected_circle}
                quota={shareholder.quota}
            />
        )
    })


    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            const handleClickOutside = (event) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.toggleShareholdersPopup();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className={classes.Shareholders}>
            <div className={classes.Container} ref={wrapperRef}>
                <div className={classes.ShareholdersTitle}>SHAREHOLDER MAP</div>
                <div className={classes.ShareholdersContainer}>
                    <div className="CEO_other_details" style={{ width: "100%" }}>
                        <div style={{ maxWidth: "670px", maxHeight: "345px", overflow: "auto" }}>
                            {shareholdersHTML}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default ShareholdersPopup;
