import React, { forwardRef, useState, useImperativeHandle } from 'react';
import classes from './LimitsAndSpreads.module.scss';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import spreads from '../../../queries/Spreads/Spreads'
import update_spreads from '../../../mutations/Spreads/CreateSpread'

const SpreadsTable = forwardRef((props, ref) => {

  const editSpreads = props.editSpreads;
  const { loading, error, data } = useQuery(spreads);
  const [updateSpread, { data_mutation }] = useMutation(update_spreads, { refetchQueries: [{ query: spreads }] })
  const [EUR, set_EUR] = useState(null)
  const [USD, set_USD] = useState(null)
  const [GBP, set_GBP] = useState(null)
  const [CHF, set_CHF] = useState(null)
  const [spreads_data, setSpreads] = useState(null)

  useImperativeHandle(ref, () => ({
    portalSpreadsMutation() {
      spreads_data.forEach(spread => {
        updateSpread({
          variables: { spread_id: spread.id, attributes: { saleSpread: eval(spread.currency), currency: spread.currency } }
        })
      })
    }
  }));

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  let portal_spreads = null

  if (spreads_data == null) {
    setSpreads(data.spreads)
  }
  portal_spreads = data.spreads.map(spread => {
    if (eval(spread.currency) == null) {
      eval("set_" + spread.currency)(spread.saleSpread)
    }

    return (
      <tr key={spread.id}>
        <td className={classes.Currency}>
          <span><b>{spread.currency}</b></span>
          <span style={{ color: "#659278", marginLeft: 20 }} >124, 6100</span>
        </td>
        <td>
          {editSpreads ?
            <>+<input className={classes.TextInput} name={spread.currency} type="text" value={eval(spread.currency)} onChange={(e) => { changeSpread(e) }} /></> :
            <>+ {spread.saleSpread}</>}
        </td>
      </tr>
    )
  })

  const changeSpread = (e) => {
    eval("set_" + e.target.name)(e.target.value)
  }



  return (<table className={classes.SpreadsTable}>
    <thead>
      <tr>
        <th className={classes.Currency}></th>
        <th style={{ float: "left" }}>Sale:</th>
      </tr>
    </thead>
    <tbody>
      {portal_spreads}
    </tbody>
  </table >);
})

export default SpreadsTable;
