import React from 'react';
import classes from './TransactionDetailsCard.module.scss';
import NumberFormat from 'react-number-format';

import getSymbolFromCurrency from 'currency-symbol-map'
var dateFormat = require('dateformat');

const TransactionDetailsCard = (props) => {

    const index = props.transactionSelectedIndex;
    const type = props.transactionSelectedType;
    let cardStyle = { top: 120, background: null };
    let triangleStyle = { top: 43 + (index - 1) * 120, bottom: null, borderColor: null };

    switch (type) {
        case 'purchase': {
            cardStyle.background = '#C2D0D3';
            triangleStyle.borderColor = 'transparent #C2D0D3 transparent transparent';
            break;
        }
        case 'sale': {
            cardStyle.background = '#ECF2EF';
            triangleStyle.borderColor = 'transparent #ECF2EF transparent transparent';
            break;
        }
        case 'in-store': {
            cardStyle.background = '#F0F0F0';
            triangleStyle.borderColor = 'transparent #F0F0F0 transparent transparent';
            break;
        }
        default:
        //do nothing
    }

    let account_type = null

    switch (props.user.typology) {
        case 'service_point': {
            account_type = "Service Point";
            break;
        }
        case 'business_account': {
            account_type = "Business Account";
            break;
        }
        case 'private_account': {
            account_type = "Private Account";
            break;
        }
        default:
        //do nothing
    }

    const currency_symbol = getSymbolFromCurrency(props.currency)
    const paid_currency_symbol = getSymbolFromCurrency(props.paid_currency)

    return (

        <div className={classes.DetailsCard}
            style={cardStyle}>
            <div className={classes.Triangle} style={triangleStyle}></div>
            <div className={classes.Content}>
                <div className={classes.Title}>
                    {type === 'in-store' ? 'REGISTRATION DETAILS' : 'OFFER DETAILS'}
                </div>
                <div className={classes.Section1}>
                    <div>
                        <span><b> {type === 'in-store' ? 'Registration Completed: ' : 'Offer Placed: '} </b>{props.datetime}</span>
                        <span style={{ float: "right" }}><b>Fixed Order</b></span>
                    </div>
                    <div>
                        <span><b>{type === 'in-store' ? 'Order Number: ' : 'Offer Number: '}</b>{props.order_number}</span>
                    </div>
                </div>
                <div className={classes.Section2}>
                    <div className={classes.Col1}>
                        <div className={classes.SubTitle}><b>Item Details</b></div>
                        <div>
                            <div>
                                <span><NumberFormat displayType={"text"} thousandSeparator={true} suffix={" " + props.currency} value={props.amount} /></span>
                                <span> at {props.rate} {currency_symbol}/{paid_currency_symbol}1 </span>
                            </div>
                            <div>Order Total: <NumberFormat displayType={"text"} thousandSeparator={true} suffix={" " + props.paid_currency} value={props.paid_price} /></div>
                        </div>

                        {type === 'in-store' && props.customer ?
                            <div style={{ marginTop: 50 }}>
                                <div className={classes.SubTitle}><b>Client Information</b></div>
                                <div style={{ marginTop: 31 }}>
                                    <div>{props.customer.firstName} {props.customer.lastName}</div>
                                    <div>{props.customer.personalIdNumber}</div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div>{props.customer.address}</div>
                                    <div>{props.customer.postalCode} {props.customer.city}, {props.customer.country}</div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div>{props.customer.email}</div>
                                    <div>{props.customer.cellphone}</div>
                                </div>

                                {/*<button className={classes.YellowButton}
                                    style={{ width: 231, marginTop: 31 }}>REGISTRATION HISTORY</button>*/}
                            </div> : null}
                    </div>
                    <div className={classes.Col2}>
                        <div className={classes.SubTitle}><b>User Information</b></div>
                        <div style={{ display: "flex" }}>
                            <div><b>Account:  </b></div>
                            <div style={{ marginLeft: 5 }}>
                                {type === 'in-store' ?
                                    <> <div>{account_type}</div>
                                        <div><NumberFormat displayType={"text"} thousandSeparator={true} suffix={" L"} value={props.user.tradingDailyLimit} /> Limit</div></> :
                                    <> <div>Private Individual</div>
                                        <div>1.000.000,00 L</div></>}
                            </div>
                        </div>
                        <div className={classes.UserInfo}>
                            <div className={classes.Col1}>
                                <div><b>{props.user.firstName} {props.user.lastName}</b></div>
                                <div>{props.user.personalIdNumber}<br /> DOB: {dateFormat(props.user.dateOfBirth, "dd/mm/yyyy")}</div>
                                { (props.document.typology === "passport" || props.document.typology === "id_card") ?
                                      <div>
                                        National {props.document.typology === "passport" ? "Passport" : "Identity Card"} <br />
                                        {props.document.issueEntity} <br />
                                        No: {props.document.number} <br />
                                        Issued: {props.document.issueDate} <br />
                                        Expires: {props.document.expirationDate}
                                      </div>
                                    :
                                    null
                                }
                                {/*<button className={classes.YellowButton}
                                    style={{ width: 190 }}>VIEW DOCUMENTS</button>*/}
                            </div>
                            <div className={classes.Col2}>
                                <div>{props.user.address} {props.user.postalCode} <br /> {props.user.city}, {props.user.country}</div>
                                <div style={{ marginTop: 20 }}> {props.user.email} <br /> {props.user.cellphone}</div>
                                {/*<button className={classes.YellowButton}
                                    style={{ width: 190, marginTop: 20 }}>{type !== 'in-store' ? 'ORDER HISTORY' : 'REGISTRATIONS'}</button>
                                <button className={classes.YellowButton}
                                    style={{ width: 190, marginTop: 10 }}>ACCESS HISTORY</button>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>);
}

export default TransactionDetailsCard;
