import React, { useState, useEffect } from 'react';
import classes from './MainPage.module.scss';
import AllRoles from './AllRoles';
import AddRole from './AddRole';
import EditRole from './EditRole';
import { useQuery } from '@apollo/react-hooks';
import RolesQuery from '../../../queries/RoleManagement/Roles';

const Roles = (props) => {

    const [roles, setRoles] = useState([]);
    const rolesQuery = useQuery(RolesQuery, {
        onCompleted: () => {
            if (!rolesQuery.loading && !rolesQuery.error) {
                setRoles(rolesQuery.data.roles);
            }
        }
    });

    useEffect(() => {
        if (!rolesQuery.loading && !rolesQuery.error) {
            setRoles(rolesQuery.data.roles);
        }
    }, [rolesQuery]);

    const permissionList = [
        { name: "userManagement", status: true, displayName: "User Management" },
        { name: "accounts", status: true, displayName: "Accounts" },
        { name: "currencyTrader", status: true, displayName: "Currency Trader" },
        { name: "incomingPayments", status: true, displayName: "Incoming Payments" },
        { name: "outgoingPayments", status: true, displayName: "Outgoing Payments" },
        { name: "tradingBook", status: true, displayName: "Trading Book" },
        { name: "limitsSpreads", status: true, displayName: "Limits & Spreads" },
        { name: "documents", status: true, displayName: "Registration Documents" }];

    const permissionListNewRole = [
        { name: "accounts", status: true, displayName: "Accounts" },
        { name: "currencyTrader", status: true, displayName: "Currency Trader" },
        { name: "incomingPayments", status: true, displayName: "Incoming Payments" },
        { name: "outgoingPayments", status: true, displayName: "Outgoing Payments" },
        { name: "tradingBook", status: true, displayName: "Trading Book" },
        { name: "limitsSpreads", status: true, displayName: "Limits & Spreads" },
        { name: "documents", status: true, displayName: "Registration Documents" }];

    //roles
    //editRole
    //addRole
    const [view, setView] = useState(props.newRole ? 'addRole' : 'roles');
    const title = { roles: "EXISTING ROLES", editRole: "EDIT ROLE", addRole: "CREATE A NEW ROLE" };
    const [opacity, setOpacity] = useState(props.newRole ? { roles: 0.3, editRole: 0.3, addRole: 1 } : { roles: 1, editRole: 0.3, addRole: 0.3 });
    const [selectedRole, setSelectedRole] = useState(null);
    const [message, setMessage] = useState("");
    const [showMsg, setShowMsg] = useState(false);

    const select = (role) => {
        if (selectedRole === null) {
            setSelectedRole(role);
        }
        else if (selectedRole.id !== role.id) {
            setSelectedRole(role);
        }
        else setSelectedRole(null);

    }

    const handleNavOnClick = (view) => {

        if (view === "editRole" && selectedRole === null) {
            setView("roles");
            setOpacity({ roles: 1, editRole: 0.3, addRole: 0.3 });
            setShowMsg(true);
            setMessage("You must select a role before editing.");
        }
        else if (view === "editRole" && selectedRole !== null) {
            setView("editRole");
            setOpacity({ roles: 0.3, editRole: 1, addRole: 0.3 });
            setShowMsg(false);
            setMessage("");
        }
        else if (view === "roles") {
            setView(view);
            setOpacity({ roles: 1, editRole: 0.3, addRole: 0.3 });
            setShowMsg(false);
            setMessage("");
            setSelectedRole(null);
        }
        else {
            setView(view);
            setOpacity({ roles: 0.3, editRole: 0.3, addRole: 1 });
            setShowMsg(false);
            setMessage("");
        }
    }

    const handleSetMessage = (message) => {
        if (message !== "") {
            setShowMsg(true);
            setMessage(message);
        }
        else {
            setShowMsg(false);
            setMessage("");
        }
    }

    return (
        <>
            <div className={classes.Nav}>
                <i className="fas fa-user-plus fa-5x" style={{ opacity: opacity["addRole"] }} onClick={() => handleNavOnClick("addRole")}></i>
                <i className="fas fa-user-tag fa-5x" style={{ opacity: opacity["roles"] }} onClick={() => handleNavOnClick("roles")}></i>
                <i className="fas fa-user-cog fa-5x" style={{ opacity: opacity["editRole"] }} onClick={() => handleNavOnClick("editRole")}></i>
            </div>
            <div className={classes.SubHeader}>{title[view]}</div>

            {showMsg ? <div className={classes.Message}><b>{message}</b></div> : <div className={classes.Message}></div>}

            {view === "roles" &&
                <AllRoles
                    roles={roles}
                    permissions={permissionList}
                    select={select}
                    selectedRole={selectedRole} />}
            {view === "addRole" &&
                <AddRole
                    permissions={permissionListNewRole}
                    handleNavOnClick={handleNavOnClick}
                    handleSetMessage={handleSetMessage} />}
            {(view === "editRole" && selectedRole != null) &&
                <EditRole
                    selectedRole={selectedRole}
                    permissions={permissionList}
                    handleNavOnClick={handleNavOnClick}
                    handleSetMessage={handleSetMessage}
                    editUser={props.editUser} />}

        </>
    );
}

export default Roles;
