import React from 'react';
import classes from './CurrencyTrader.module.scss';

const EmptyCard = () => {
    return (
        <div className={classes.EmptyCard}></div>
    );
}

export default EmptyCard;
