import React, { useState, useEffect } from 'react';
import classes from './MainPage.module.scss';
import AddUser from './AddUser';
import AllUsers from './AllUsers';
import EditUser from './EditUser';
import { useQuery } from '@apollo/react-hooks';
import BackOfficeUsersQuery from '../../../queries/RoleManagement/BackOfficeUsers';

const Users = (props) => {

    const backOfficeUsers = useQuery(BackOfficeUsersQuery, {
        onCompleted: () => {
            if (!backOfficeUsers.loading && !backOfficeUsers.error) {
                setUserList(backOfficeUsers.data.backOfficeUsers);
            }
        }
    });
    useEffect(() => {
        if (!backOfficeUsers.loading && !backOfficeUsers.error) {
            setUserList(backOfficeUsers.data.backOfficeUsers);
        }
    }, [backOfficeUsers]);

    const [userList, setUserList] = useState([]);
    const [showSTAConfirmationPopup, setShowSTAConfirmationPopup] = useState(false);

    const toggleSTAConfirmationPopup = () => {
        setShowSTAConfirmationPopup(!showSTAConfirmationPopup);
    }

    //users
    //editUser
    //addUser
    const [view, setView] = useState(props.editSelectedUser ? 'editUser' : 'users');
    const title = { users: "ALL USERS", editUser: "EDIT USER INFORMATION", addUser: "CREATE NEW USER" };
    const [opacity, setOpacity] = useState(props.editSelectedUser ? { users: 0.3, editUser: 1, addUser: 0.3 } : { users: 1, editUser: 0.3, addUser: 0.3 });

    const [selectedUser, setSelectedUser] = useState(props.editSelectedUser);
    const [message, setMessage] = useState("");
    const [showMsg, setShowMsg] = useState(false);

    const select = (user) => {
        if (selectedUser === null) setSelectedUser(user);
        else if (selectedUser !== user) setSelectedUser(user);
        else setSelectedUser(null);

    }

    const handleNavOnClick = (view) => {

        if (view === "editUser" && selectedUser === null) {
            setView("users");
            setOpacity({ users: 1, editUser: 0.3, addUser: 0.3 });
            setShowMsg(true);
            setMessage("You must select a user before editing.");
        }
        else if (view === "editUser" && selectedUser !== null) {
            if (props.currentUser.role.name !== "super technical admin" && selectedUser.role.name === "super technical admin") {
                setShowMsg(true);
                setMessage("You can't edit a super technical admin");
            }
            else {
                setView("editUser");
                setOpacity({ users: 0.3, editUser: 1, addUser: 0.3 });
                setShowMsg(false);
                setMessage("");
            }
        }
        else if (view === "users") {
            setView(view);
            setOpacity({ users: 1, editUser: 0.3, addUser: 0.3 });
            setShowMsg(false);
            setMessage("");
            setSelectedUser(null);
        }
        else {
            setView(view);
            setOpacity({ users: 0.3, editUser: 0.3, addUser: 1 });
            setShowMsg(false);
            setMessage("");
        }
    }

    const createNewRole = () => {
        props.createNewRole();
    }

    const cancelEditUser = () => {
        setView("users");
        setOpacity({ users: 1, editUser: 0.3, addUser: 0.3 });
        setShowMsg(false);
        setMessage("");
        setSelectedUser(null);
    }

    const cancelAddUser = () => {
        setView("users");
        setOpacity({ users: 1, editUser: 0.3, addUser: 0.3 });
        setShowMsg(false);
        setMessage("");
    }

    const handleSetMessage = (message) => {
        if (message !== "") {
            setShowMsg(true);
            setMessage(message);
        }
        else {
            setShowMsg(false);
            setMessage("");
        }
    }

    return (
        <>
            <div className={classes.Nav}>
                <i className="fas fa-user-plus fa-5x" style={{ opacity: opacity["addUser"] }} onClick={() => handleNavOnClick("addUser")}></i>
                <i className="fas fa-users fa-5x" style={{ opacity: opacity["users"] }} onClick={() => handleNavOnClick("users")}></i>
                <i className="fas fa-user-cog fa-5x" style={{ opacity: opacity["editUser"] }} onClick={() => handleNavOnClick("editUser")}></i>
            </div>
            <div className={classes.SubHeader}>{title[view]}</div>

            {showMsg ? <div className={classes.Message}><b>{message}</b></div> : <div className={classes.Message}></div>}

            {view === "addUser" &&
                <AddUser
                    users={userList}
                    roles={props.roles}
                    createNewRole={createNewRole}
                    handleSetMessage={handleSetMessage}
                    cancelAddUser={cancelAddUser}
                    currentUser={props.currentUser}
                    showSTAConfirmationPopup={showSTAConfirmationPopup}
                    toggleSTAConfirmationPopup={toggleSTAConfirmationPopup} />}
            {view === "users" &&
                <AllUsers users={userList}
                    select={select}
                    selectedUser={selectedUser}
                    currentUser={props.currentUser}
                    showSTAConfirmationPopup={showSTAConfirmationPopup}
                    toggleSTAConfirmationPopup={toggleSTAConfirmationPopup} />}
            {(view === "editUser" && selectedUser != null) &&
                <EditUser
                    createNewRole={createNewRole}
                    cancelEditUser={cancelEditUser}
                    selectedUser={selectedUser}
                    handleSetMessage={handleSetMessage}
                    users={userList}
                    roles={props.roles}
                    currentUser={props.currentUser}
                    showSTAConfirmationPopup={showSTAConfirmationPopup}
                    toggleSTAConfirmationPopup={toggleSTAConfirmationPopup} />}

        </>
    );
}

export default Users;