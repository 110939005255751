import React, { useState } from 'react';
import classes from './CurrencyTrader.module.scss';
import CalendarFilterModal from '../Modals/CurrencyTrader/CalendarFilterModal/CalendarFilterModal';
import { useQuery } from '@apollo/react-hooks';
import OrderStats from '../../../queries/CurrencyTrader/OrderStats';
import ProfitLossQuery from '../../../queries/CurrencyTrader/ProfitLoss';
import NumberFormat from 'react-number-format';
import { API } from 'aws-amplify';
import Amplify from 'aws-amplify';
import getSymbolFromCurrency from 'currency-symbol-map';

Amplify.configure({
    API: {
        graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
        graphql_headers: async () => ({
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
    }
});

const DetailsCard = (props) => {

    var dateFormat = require('dateformat');

    const today = new Date();
    const transactionStatus = props.transactionStatus;
    const [showCalendarFilter, setShowCalendarFilter] = useState(false);
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0));
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0));
    const [runFilter, setRunFilter] = useState(false)
    const { loading, data } = useQuery(OrderStats, {
        variables: {
            typology: "admin_currency_trader",
            start_date: dateFormat(startDate, "dd/mm/yyyy"),
            end_date: dateFormat(endDate, "dd/mm/yyyy")
        },
        onCompleted: () => {
            if (!loading && !runFilter) {
                setOrderStatistics(data.currencyDetail);
            }
        }
    });
    const profitLossQuery = useQuery(ProfitLossQuery, {
        variables: {
            start_date: dateFormat(startDate, "dd/mm/yyyy"),
            end_date: dateFormat(endDate, "dd/mm/yyyy")
        }
    });
    const [orderStatistics, setOrderStatistics] = useState(null);
    const [profitLossData, setProfitLossData] = useState([]);;

    let profitLossHtml = profitLossData.map((item, index) =>
        <ProfitLoss key={index} item={item} />);

    const toggleFilterModal = () => {
        setShowCalendarFilter(!showCalendarFilter);
    }

    const closeFilterModal = () => {
        setShowCalendarFilter(false);
    }

    const handleCalendarFilter = async (startDate, endDate) => {

        setShowCalendarFilter(false);
        setStartDate(startDate);
        setEndDate(endDate);
        setRunFilter(true);
        let stats = await API.graphql({
            query: OrderStats,
            variables: {
                typology: "admin_currency_trader",
                start_date: dateFormat(startDate, "dd/mm/yyyy"),
                end_date: dateFormat(endDate, "dd/mm/yyyy")
            }
        });

        if (stats) {
            setOrderStatistics(stats.data.currencyDetail);
        }

        let profitLoss = await API.graphql({
            query: ProfitLossQuery,
            variables: {
                start_date: dateFormat(startDate, "dd/mm/yyyy"),
                end_date: dateFormat(endDate, "dd/mm/yyyy")
            }
        });

        if (profitLoss) {
            const dataTemp = profitLoss.data.profitLoss;
            setProfitLossData(dataTemp);
        }
    }

    if (!profitLossQuery.loading && !runFilter) {
        const dataTemp = profitLossQuery.data.profitLoss;
        profitLossHtml = dataTemp.map((item, index) =>
            <ProfitLoss key={index} item={item} />);
    }

    return (
        <div className={classes.Details}>
            <div className={classes.Title}>
                <b>
                    {(transactionStatus === 'default' || transactionStatus === 'all') ?
                        'All ' : transactionStatus.charAt(0).toUpperCase() + transactionStatus.slice(1) + ' '
                    }
            Transactions:
                </b>
                <div className="filterModalIcon" style={{ alignSelf: "center", marginLeft: "auto", position: "relative" }} onClick={() => toggleFilterModal()}>

                    <i className={["fas fa-calendar-alt fa-sm", classes.CalendarIcon].join(' ')} ></i>

                    {showCalendarFilter ?
                        <CalendarFilterModal closeModal={closeFilterModal} handleCalendarFilter={handleCalendarFilter} startDate={startDate} endDate={endDate} /> : null}
                </div>
            </div>
            <div className={classes.Row}><b>Number of Trasactions: </b>{orderStatistics !== null && orderStatistics.numberOfTransactions}</div>
            <div className={classes.Row}><b>Breakdown: </b>{orderStatistics !== null && orderStatistics.numberOfPurchases} purchases, {orderStatistics !== null && orderStatistics.numberOfSales} sales</div>

            {profitLossHtml}

        </div >
    );
}

export default DetailsCard;

const ProfitLoss = (props) => {

    const item = props.item;

    return (
        <div style={{ marginTop: 5 }}><b>{item.currency} Profit/Loss: </b>
            <span style={(item.profit >= 0 ? { color: "#649177" } : { color: "#9A2020" })}>
                {item.profit > 0 && "+"} <NumberFormat displayType={"text"} thousandSeparator={true} value={item.profit} /> {" " + (item.currency !== "CHF" ? getSymbolFromCurrency(item.currency) : "₣")}
            </span>
        </div>
    );
}
