import React, { useState, useRef, useEffect } from 'react';
import classes from './LimitsAndSpreads.module.scss';
import SearchInput from '../../UI/SearchInput/SearchInput'
import SpreadsTable from './SpreadsTable';
import LimitsTable from './LimitsTable';
import UserSpreadDetail from './UserSpreadDetails/UserSpreadDetail'
import NewSpreadScreen from './UserSpreadDetails/NewSpreadScreen'
import NewLimitScreen from './UserLimit/NewLimitScreen'
import users_query from '../../../queries/Users'
import groups_query from '../../../queries/Groups/Groups'
import { API, graphqlOperation } from 'aws-amplify'
import Amplify from 'aws-amplify';
import PenaltyPercentage from './PenaltyPercentage';

Amplify.configure({
    API: {
        graphql_endpoint: process.env.REACT_APP_GRAPHQL_URL,
        graphql_headers: async () => ({
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        })
    }
});


const LimitsAndSpreads = () => {
    const [spreadExceptionItems, setSpreadExceptionItems] = useState([])
    const [spreadExceptionUser, setSpreadExceptionUser] = useState('');
    const [editSpreads, setEditSpreads] = useState(false);
    const [showSpreadsException, setShowSpreadsException] = useState(false);
    const [editSpreadsException, setEditSpreadsException] = useState(false);
    const [addSpreadsException, setAddSpreadsException] = useState(false);
    const [limitsExceptionItems, setLimiExceptionItems] = useState([])
    const [limitsExceptionUser, setLimitsExceptionUser] = useState('');
    const [editLimits, setEditLimits] = useState(false);
    const [showLimitsException, setShowLimitsException] = useState(false);
    const [editLimitsException, setEditLimitsException] = useState(false);
    const [addLimitsException, setAddLimitsException] = useState(false);
    const [handleEditLimitMutation, setHandleEditLimtMutation] = useState(false)
    const [spread_exception_removed, setSpreadExceptionRemoved] = useState(false)
    const childRef = useRef();

    const fetchData = async () => {
        const spread_users = []
        const limit_users = []
        let fetched_users = []
        let fetched_groups = []
        try {
            fetched_users = await API.graphql(graphqlOperation(users_query))
            fetched_groups = await API.graphql(graphqlOperation(groups_query))
        }
        catch (error) { }

        fetched_groups.data.groups.forEach(group => {
            if (group.spreads.length > 0) {
                spread_users.push({ type: 'group', name: group.name, id: group.id })
            }
            limit_users.push({ type: 'group', name: group.name, user_id: group.id, limit: group.limit })
        })

        fetched_users.data.users.forEach(user => {
            if (user.spreads.length > 0) {
                spread_users.push({ type: 'individual', name: user.globalName, id: user.id })
            }
            if (user.limitException === true) {
                limit_users.push({ type: 'individual', name: user.globalName, user_id: user.id, limit: user.tradingDailyLimit })
            }

        })

        setSpreadExceptionItems(spread_users)
        setLimiExceptionItems(limit_users)
    }


    useEffect(() => {
        fetchData()
        setSpreadExceptionRemoved(false)
    }, [addLimitsException, spread_exception_removed]);

    const handleEditSpreads = () => {
        if (editSpreads)
            childRef.current.portalSpreadsMutation();
        setEditSpreads(!editSpreads);
    }

    const handleShowSpreadsException = (user) => {
        setSpreadExceptionUser(user);
        setShowSpreadsException(true);
    }

    const handleHideSpreadsException = () => {
        setSpreadExceptionUser('');
        setShowSpreadsException(false);
        setEditSpreadsException(false);
    }

    const toggleAddSpreadsException = () => {
        setAddSpreadsException(!addSpreadsException);
        fetchData();
    }

    const toggleEditSpreadsException = async () => {
        if (editSpreadsException)
            childRef.current.exceptionSpreadsMutation();
        setEditSpreadsException(!editSpreadsException);

    }


    const handleEditLimits = () => {
        if (editLimits)
            childRef.current.updateLimitsMutation();
        setEditLimits(!editLimits);
    }

    const handleShowLimitsException = (user) => {
        setLimitsExceptionUser(user);
        setShowLimitsException(true);
    }

    const handleHideLimitsException = () => {
        setLimitsExceptionUser();
        setShowLimitsException(false);
        setEditLimitsException(false);
    }



    const toggleAddLimitsExceptions = () => {
        setAddLimitsException(!addLimitsException);
    }

    const toggleEditLimitsExceptions = () => {
        if (editLimitsException) {
            setHandleEditLimtMutation(true)
        }
        else {
            setHandleEditLimtMutation(false)
        }
        setEditLimitsException(!editLimitsException);
    }

    const close_exception_spread_edit = () => {
        setEditSpreadsException(false);
    }

    const spreadsExceptionsComponent = (<div className={classes.Content}>
        <div className={classes.SearchInput}>
            <SearchInput key="SearchInput" items={spreadExceptionItems} onSelectItem={handleShowSpreadsException} onRemoveSpread={() => setSpreadExceptionRemoved(true)} />
        </div>
        <div>
            <i className={["fas fa-plus-circle fa-2x", classes.PlusIcon].join(' ')}
                onClick={toggleAddSpreadsException}></i>
        </div>
    </div>);

    const addSpreadsExceptionComponent = (<NewSpreadScreen toggleAdd={toggleAddSpreadsException} />);

    const showSpreadsExceptionComponent = (<UserSpreadDetail close_edit_screen={close_exception_spread_edit} ref={childRef} modifiable={editSpreadsException} hideException={handleHideSpreadsException} user={spreadExceptionUser} />)

    const limitsExceptionsComponent = (<div className={classes.Content}>
        <div className={classes.SearchInput}>
            <SearchInput key="SearchInput" items={limitsExceptionItems} onSelectItem={handleShowLimitsException} type={"limit"} modifiable={editLimitsException} handleEditLimitMutation={handleEditLimitMutation} />
        </div>
        <div>
            <i className={["fas fa-plus-circle fa-2x", classes.PlusIcon].join(' ')}
                onClick={toggleAddLimitsExceptions}></i>
        </div>
    </div>);

    const addLimitsExceptionComponent = (<NewLimitScreen toggleAdd={toggleAddLimitsExceptions} />);

    function SpreadsExceptionsPanel() {
        if (showSpreadsException) {
            return showSpreadsExceptionComponent
        }
        else if (addSpreadsException) {
            return addSpreadsExceptionComponent
        }
        else {
            return spreadsExceptionsComponent
        }
    }

    function LimitsExceptionsPanel() {
        if (showLimitsException) {
            if (editLimitsException) {
                // return editLimitsExceptionComponent
            }
            else {
                // return showLimitsExceptionComponent
            }
        }
        else if (addLimitsException) {
            return addLimitsExceptionComponent
        }
        else {
            return limitsExceptionsComponent
        }
    }


    return (

        <div className={classes.LimitsAndSpreads}>
            <div style={{ width: "100%", maxWidth: 726, marginRight: 30 }}>
                <div className={classes.Card} style={{ minHeight: 1000, height: "100%" }}>
                    <div className={[classes.CardHeader, classes.CardHeaderText].join(' ')}>SPREADS</div>
                    <div className={classes.SubCard}>
                        <div className={classes.SubHeader}>
                            <span className={classes.SubHeaderWithIcon}>SITE STANDARDS</span>
                            <i className="fas fa-pen"
                                style={editSpreads ? { opacity: "0.3" } : { opacity: "1" }}
                                onClick={handleEditSpreads}></i>
                        </div>
                        <SpreadsTable ref={childRef} editSpreads={editSpreads} />
                    </div>
                    <div className={classes.SubCard}>
                        <div className={classes.SubHeader}>
                            <span>EXCEPTIONS</span>
                            <i className="fas fa-pen"
                                style={!showSpreadsException ? { "visibility": "hidden" } : (editSpreadsException ? { "opacity": "0.3" } : { "opacity": "1" })}
                                onClick={toggleEditSpreadsException}></i>
                        </div>
                        <SpreadsExceptionsPanel />
                    </div>

                </div>
                <PenaltyPercentage />
            </div>
            <div className={classes.Card}>
                <div className={[classes.CardHeader, classes.CardHeaderText].join(' ')}>DAILY LIMITS</div>
                <div className={classes.SubCard}>
                    <div className={classes.SubHeader}>
                        <span className={classes.SubHeaderWithIcon}>SITE STANDARDS</span>
                        <i className="fas fa-pen"
                            style={editLimits ? { opacity: "0.3" } : { opacity: "1" }}
                            onClick={handleEditLimits}></i>
                    </div>
                    <LimitsTable ref={childRef} editLimits={editLimits} />
                </div>
                <div className={classes.SubCard}>
                    <div className={classes.SubHeader}>
                        <span className={classes.SubHeaderWithIcon}>EXCEPTIONS</span>
                        <i className="fas fa-pen"
                            style={editLimitsException ? { opacity: "0.3" } : { opacity: "1" }}
                            onClick={toggleEditLimitsExceptions}></i>
                    </div>
                    <LimitsExceptionsPanel />
                </div>
            </div>
        </div>


    );
}

export default LimitsAndSpreads;
