import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginTool from './LoginTool';
import ForgetPasswordTool from './ForgetPasswordTool';
import VerificationTool from './VerificationTool';
import CodeTool from "./CodeTool/CodeTool"
import ToolbarLogin from "../Header/ToolbarLogin";
import ResetPassword from './ResetPassword';

class Login extends Component {
  render() {
    return (
      <div>
        <ToolbarLogin />

        <Switch>
          <Route path={"/login"} exact component={LoginTool} />
          <Route path={"/login/forgetPassword"} exact component={ForgetPasswordTool} />
          <Route path={"/login/verification"} exact component={VerificationTool} />
          <Route path={"/login/verification/info"} exact component={VerificationTool} />
          <Route path={"/login/code"} exact component={CodeTool} />
          <Route path={"/login/reset_password"} exact component={ResetPassword} />
          <Redirect from="/" to="/login" />

        </Switch>
      </div>
    );
  }
}

export default Login;
