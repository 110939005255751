import gql from 'graphql-tag';

export default gql`
mutation createSpread($attributes: SpreadInput!, $user_id: ID, $group_id: ID, $spread_id: ID) {
  createSpread(attributes: $attributes, groupId: $group_id, spreadId: $spread_id, userId: $user_id) {
    id
  }
}

`;
