import React, { useEffect, useRef } from 'react';
import classes from '../Accounts.module.scss';

const ShareholdersPopup = (props) => {

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          props.closePopup();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className={classes.Shareholders}>
      <div className={classes.Container} ref={wrapperRef}>
        <div className={classes.ShareholdersTitle}>{props.name}</div>
        <div className={classes.ShareholdersContainer}>
          <div className="CEO_other_details" style={{ width: "100%" }}>
            <div style={{ maxWidth: "670px", maxHeight: "345px", overflow: "auto" }}>
              {props.headquarter ?
                <div>
                  <b>RegistrationCertificate: </b>
                  <a href={props.headquarter.registrationCertificateUrl} target="_blank" rel="noopener noreferrer">
                    {props.headquarter.registrationCertificateFilename}
                  </a><br />
                  <b>LicenceCertificate: </b>
                  <a href={props.headquarter.licenceCertificateUrl} target="_blank" rel="noopener noreferrer">
                    {props.headquarter.licenceCertificateFilename}
                  </a>
                </div>
                : props.bank_accounts ?
                  props.bank_accounts.map(bank => {
                    return (
                      <div>
                        <b> {bank.currency} </b>
                        <a href={bank.verificationDocumentUrl} target="_blank" rel="noopener noreferrer">
                          {bank.verificationDocumentFilename}
                        </a><br />
                      </div>
                    )
                  })
                  : null}
            </div>
          </div>
        </div>
      </div>
    </div>);
}

export default ShareholdersPopup;
