import React from 'react';
import classes from './CurrencyTrader.module.scss';
import getSymbolFromCurrency from 'currency-symbol-map';
import NumberFormat from 'react-number-format';
import BankIcon from './BankIcon';

const exactMath = require('exact-math');

const CardContent = (props) => {
    const transaction = props.transaction;

    return (<>
        <div className={classes.FirstRow} style={{ alignItems: "center" }}>
            <div className={transaction.type === 'purchase' ? classes.PurchaseIcon : classes.SalesIcon} >
                <div>{transaction.type === 'purchase' ? 'P' : 'S'}</div>
            </div>
            {transaction.CI ?
                <div className={classes.CI_Icon} >
                    <div>CI</div>
                </div> :
                <BankIcon transaction={transaction} payOnlineOrder={props.payOnlineOrder} />
            }
            <span>{transaction.type === 'purchase' ? 'Purchase' : 'Sale'}</span>
        </div>
        <div style={{ fontSize: 20 }}>
            <span>
                {
                    typeof (transaction.amount) != "undefined" ?
                        <NumberFormat
                            value={transaction.amount}
                            displayType={'text'}
                            prefix={getSymbolFromCurrency(transaction.amountSoldCurrency || transaction.amountPurchasedCurrency)}
                            thousandSeparator=","
                            decimalSeparator="." /> : transaction.amountSoldCents || transaction.amountPurchasedCents
                }
            </span>

            <span style={{ float: "right" }}>
                {typeof (transaction.amount) != "undefined" ?
                    <NumberFormat
                        value={exactMath.mul(transaction.amount, transaction.rate).toFixed(2)}
                        displayType={'text'}
                        suffix={transaction.amountPaidCurrency === "ALL" ? " L" : null}
                        prefix={transaction.amountPaidCurrency === "ALL" ? null : transaction.amountPaidCurrency}
                        thousandSeparator=","
                        decimalSeparator="." /> :
                    transaction.amountPaidCents}
            </span>
        </div>
        <div style={{ fontSize: 12 }}>
            <span>{transaction.portfolio.user.globalName}: <NumberFormat
                value={transaction.portfolio.user.tradingDailyLimit} displayType={'text'} thousandSeparator="." decimalSeparator="," /> L</span>
            <span style={{ float: "right" }}>
                Rate: {transaction.rate + " " + getSymbolFromCurrency(transaction.amountPaidCurrency) + "/" +
                    (transaction.type === 'purchase' ? getSymbolFromCurrency(transaction.amountPurchasedCurrency) : getSymbolFromCurrency(transaction.amountSoldCurrency))}</span>
        </div>
    </>);
}

export default CardContent;
