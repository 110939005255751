import gql from 'graphql-tag';

export default gql`

mutation confirmEmail($token: String!) {
  confirmEmail(token: $token) {
    success
  }
}

`;
