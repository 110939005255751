import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import classes from './MainPage.module.scss';
import CreateRole from '../../../mutations/RoleManagement/CreateRole';
import RolesQuery from '../../../queries/RoleManagement/Roles';

const AddRole = (props) => {


    const [permissions, setPermmissions] = useState(props.permissions);
    const [roleName, setRoleName] = useState("");
    const [createRole] = useMutation(CreateRole, { refetchQueries: [{ query: RolesQuery }] });
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const changeStatus = (permissionName) => {
        let permissionsTemp = permissions;
        let permission = permissionsTemp.filter(item => item.name === permissionName)[0];
        let index = permissionsTemp.findIndex(item => item.name === permissionName);
        if (permission.status === true) permission.status = false;
        else permission.status = true;
        permissionsTemp.splice(index, 1, permission);
        setPermmissions([...permissionsTemp]);
    }

    const html = permissions.map(permission =>
        <div key={permission.name} className={classes.Permission}>
            <b>{permission.displayName}</b>
            {permission.status === true ?
                <i className={["fas fa-toggle-on fa-lg", classes.ToggleIcon].join(' ')} onClick={() => changeStatus(permission.name)}></i> :
                <i className={["fas fa-toggle-on fa-lg fa-flip-horizontal", classes.LowOpacityIcon].join(' ')} onClick={() => changeStatus(permission.name)}></i>
            }
        </div>);

    const handleOnChange = (event) => {
        event.persist();
        setRoleName(event.target.value);
    }

    const handleOnSave = () => {
        if (roleName === "" || roleName === null) {
            props.handleSetMessage("Please enter the name of the role");
        }
        else {
            props.handleSetMessage("");
            let attributes = {};
            permissions.forEach(element => {
                attributes[element.name] = element.status;
            });
            attributes["name"] = roleName;
            createRole({
                variables: {
                    attributes: attributes
                }
            }).then(result => {
                setShowSuccessMsg(true);
            }).catch(error => {
                console.log(error);
                if (error.message === "GraphQL error: Invalid input: Name has already been taken") {
                    props.handleSetMessage("Name has already been taken");
                }
            });
        }
    }

    return (<div className={classes.AddRole}>
        {!showSuccessMsg ?
            <>
                <div className={classes.RoleName}>
                    <div className={classes.Label}>NAME YOUR ROLE</div>
                    <div>
                        <input type="text" placeholder="Name" value={roleName} className={classes.TextInput} style={{ width: 345 }} onChange={handleOnChange} />
                    </div>
                </div>

                <div className={classes.Permissions}>
                    <div className={classes.Label}>SELECT PERMISSIONS</div>

                    <div className={classes.PermissionList}>
                        {html}
                    </div>
                </div>

                <div className={classes.ActionButton}>
                    <button className={classes.YellowButton} onClick={handleOnSave}>SAVE</button>
                    <button className={classes.WhiteButton} onClick={() => props.handleNavOnClick("roles")}> CANCEL</button>
                </div>
            </> :
            <div>The role was created!</div>
        }
    </div>);
}

export default AddRole;
