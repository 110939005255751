import gql from 'graphql-tag';

export default gql`
mutation uploadRegistrationdocument($typology: String!, $signed_blob_id: ID! ) {
  uploadRegistrationDocument(typology: $typology, signedBlobId: $signed_blob_id) {
    success
  }
}

`;
