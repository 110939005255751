import React, { Component } from 'react';
import Calendar from 'react-calendar';
import './stili.css'

class SettingsCalendar extends Component {

  render() {

    return (

      <Calendar
        onChange={this.props.onChange}
        value={this.props.value}
        locale={"al-AL"}
        formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'Th', 'F', 'Su'][value.getDay()]}
        showNeighboringMonth={false}
        nextLabel={<i className="fas fa-caret-right fa-lg"></i>}
        next2Label={<i className="fas fa-angle-double-right fa-md"></i>}

        prevLabel={<i className="fas fa-caret-left fa-lg"></i>}
        prev2Label={<i className="fas fa-angle-double-left fa-md"></i>}

      />
    );
  }
}

export default SettingsCalendar;
