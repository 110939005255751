import gql from 'graphql-tag';

export default gql`
query ($offset: Int, $limit: Int, $group_id: ID, $currency: String, $group_name: String) {
  groups(offset: $offset, limit: $limit, groupId: $group_id, groupName: $group_name) {
    id
    limit
    name
    users {
      id
      firstName
      lastName
      globalName
      createdAt
      tradingDailyLimit
      typology
      balances(currency: $currency) {
        currency
        totalBalance
      }
      group{
        id
        name
      }
      servicePoint {
        commercialName
      }
    }
    spreads{
      currency
      saleSpread
    }
  }
}
`;
