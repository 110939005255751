import gql from 'graphql-tag';


export default gql`
query ($limit: Int, $offset: Int, $date: String, $grouped: Boolean, $attributes: FilterAccountsInput) {
  users(limit: $limit, offset: $offset, grouped:$grouped, attributes: $attributes) {
    id
    firstName
    lastName
    globalName
    typology
    dateOfBirth
    cityOfBirth
    personalIdNumber
    postalCode
    address
    email
    cellphone
    city
    country
    createdAt
    tradingDailyLimit
    guarantee
    companyName
    companyFiscalCode
    companyIdentificationNumber
    approved
    state
    signedContractUrl
    signedContractFilename
      missingPayments{
      ... on PurchaseOrder{
        number
        missingPayment
        amountPaidCurrency
      }
      ... on SaleOrder{
       number
        missingPayment
        amountSoldCurrency
      }
    }
    group{
      id
      name
    }
    document {
      typology
      issueDate
      issueEntity
      expirationDate
      number
      description
      identityDocumentUrl
      identityDocumentFilename
    }
    spreads {
      currency
      saleSpread
    }
    bankAccounts {
      id
      currency
      iban
      bankName
      status
      verificationDocumentUrl
      verificationDocumentFilename
    }
    balances(date: $date) {
      averageTransactionValue
      currency
      limit
      numberOfTransactions
      totalBalance
      totalEarnings
      totalPurchaseValue
      totalSaleValue
      numberOfSales
      numberOfPurchases
    }
    servicePoint {
      postalCode
      commercialName
      identificationNumber
      address
      city
      country
      email
      phone
      fax
    }
    headquarter {
      ceo
      address
      postalCode
      city
      country
      email
      shareholders
      registrationCertificateUrl
      registrationCertificateFilename
      licenceCertificateUrl
      licenceCertificateFilename
    }
    employees{
      firstName
      lastName
    }
  }
}
`;
