import React, { forwardRef, useState, useImperativeHandle } from 'react';
import classes from './LimitsAndSpreads.module.scss';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from '@apollo/react-hooks';
import limits from '../../../queries/Limits/StandardLimits'
import update_limits from '../../../mutations/Limits/UpdateLimit'
import NumberFormat from 'react-number-format';

const LimitsTable = forwardRef((props, ref) => {

  const editLimits = props.editLimits;
  const [standard_limits, setStandardLimits] = useState(null)
  const { loading, error, data } = useQuery(limits);
  const [updateLimit, { data_mutation }] = useMutation(update_limits, { refetchQueries: [{ query: limits }] })

  useImperativeHandle(ref, () => ({
    updateLimitsMutation() {
      standard_limits.forEach(limit => {
        updateLimit({
          variables: { limit_id: limit.id, limit: parseInt(limit.limit) }
        })
      })
    }
  }));

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  else
    if (standard_limits == null) {
      setStandardLimits(data.standardLimits)
    }

  const return_range = (range) => {
    switch (range) {
      case '<1': {
        return "Less than 1.000.000 L"
        break;
      }
      case '1-3': {
        return "1.000.000 - 3.000.000 L"
        break;
      }
      case '3-5': {
        return "3.000.000 - 5.000.000 L"
        break;
      }
      case '>5': {
        return "more than 5.000.000 L"
        break;
      }
      default:
      //do nothing
    }
  }

  const onValueChange = (limit_value, limit_id) => {
    let new_list = [...standard_limits]
    new_list.forEach(limit => {
      if (limit.id === limit_id) {
        limit.limit = limit_value
      }
    })
    setStandardLimits(new_list)
  }

  return (<table className={classes.LimitsTable}>
    <thead>
      <tr>
        <th></th>
        <th>Limit:</th>
      </tr>
    </thead>
    <tbody>
      {data.standardLimits.map(limit => {
        return (
          <tr key={limit.id}>
            <td>{return_range(limit.range)}</td>
            <td>{editLimits ?
              <><NumberFormat className={classes.TextInput} thousandSeparator={true} value={limit.limit} onValueChange={(value) => onValueChange(value.value, limit.id)} /></> :
              <><NumberFormat displayType={"text"} thousandSeparator={true} value={limit.limit} /></>}
            </td>
          </tr>
        )
      })}
    </tbody>
  </table>);
})

export default LimitsTable;
