import React from 'react';
import classes from './Accounts.module.scss';
import Card from './Card';
import NumberFormat from 'react-number-format';

const GroupDetails = (props) => {

    const group = props.selectedItem;

    const accountCards = group.users.map((account, index) =>
        <Card key={index} index={index} account={account} cardType={"accountCard"} action={null} />);

    let spreadsHTML;
    if (group.spreads.length > 0) {
        spreadsHTML = group.spreads.map((item, index) => <div key={index} className={classes.TableRow}>
            <div><b>{item.currency}</b></div><div>{item.saleSpread}</div>
        </div>)
    }

    return (<div className={classes.GroupDetails}>
        <div style={{ width: 240 }}>
            <div style={{ marginBottom: 50 }}><b>If special spreads have<br />
                 been assigned to this <br />
                 group, they will<br />
                 appear below.</b></div>

            <div className={classes.SubTitle}><b>DAILY LIMIT </b><NumberFormat
                value={group.limit}
                displayType={'text'}
                thousandSeparator=","
                decimalSeparator="." /></div>

            {group.spreads.length > 0 ?
                <>
                    <div className={classes.SubTitle} style={{ marginTop: 30 }}><b>SALES</b></div>
                    <div className={classes.PurchasesSpreads}>
                        {spreadsHTML}
                    </div>
                </> : null
            }

        </div>
        <div style={{ width: 590, height: 494, overflowY: "auto", paddingRight: 15 }}>
            {accountCards}
        </div>
    </div>);
}

export default GroupDetails;
