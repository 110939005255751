import React from 'react';
import classes from './FourDots.module.scss';

function FourDots(props) {
  var size = props.size;
  var dotSize = props.dotSize;
  return (
    <div className={classes.FourDotsContainer} style={{ height: size, width: size, marginLeft: 15, marginRight: 10 }}>
      <i className="fas fa-circle" style={{ fontSize: `${dotSize}px` }}></i>
      <i className="fas fa-circle" style={{ fontSize: `${dotSize}px` }}></i>
      <i className="fas fa-circle" style={{ fontSize: `${dotSize}px` }}></i>
      <i className="fas fa-circle" style={{ fontSize: `${dotSize}px` }}></i>
    </div>
  );
}


export default FourDots;
