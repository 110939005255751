import gql from 'graphql-tag';

export default gql`
mutation createRole($attributes: RoleInput!, $role_id: ID) {
  createRole(attributes: $attributes, roleId: $role_id) {
    id
    name
  }
}
`;

// Creates and updates role. Returns null if role name already exists. If role_id is given it updates attibutes of role
